import { ICategory, IProduct } from 'common/models';
import { ApiClient } from 'common/api';

enum CategoryNodes {
  Categories = '/categories',
}

export class ApiCategoryService {
  private static instance: ApiCategoryService | null = null;

  private apiService: ApiClient;

  private constructor() {
    this.apiService = ApiClient.getInstance();
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiCategoryService();
    }

    return this.instance;
  }

  async getAll(): Promise<ICategory[]> {
    const response = await this.apiService.sendRequest<ICategory[]>('get', CategoryNodes.Categories, {}, false);

    return response;
  }

  async create(title: string, parentId: string, image?: File): Promise<ICategory[]> {
    const response = await this.apiService.sendRequest<ICategory[]>(
      'post',
      CategoryNodes.Categories,
      {
        title: title,
        parentId: parentId,
        file: image,
      },
      true,
      true
    );

    return response;
  }

  async getProductsByCategoryId(categoryId: string): Promise<IProduct[]> {
    const address = `${CategoryNodes.Categories}/${categoryId}`;
    const response = await this.apiService.sendRequest<IProduct[]>('get', address);
    return response;
  }

  async update(id: string, title: string, parentId: string, image?: File): Promise<ICategory[]> {
    const response = await this.apiService.sendRequest<ICategory[]>(
      'put',
      CategoryNodes.Categories,
      {
        id: id,
        title: title,
        parentId: parentId,
        file: image,
      },
      true,
      true
    );

    return response;
  }

  async delete(id: string, parentId: string): Promise<ICategory[]> {
    const response = await this.apiService.sendRequest<ICategory[]>('delete', CategoryNodes.Categories, {
      id: id,
      parentId: parentId,
    });

    return response;
  }
}
