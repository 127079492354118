import { ICategory } from 'common/models';
import { Stack } from 'components';
import './style.css';
import { Header } from '..';
import { ApiImageService } from 'common/api';
import { guidEmpty } from 'common/consts';

interface IProps {
  category: ICategory;
  onUpdateCategoryClick: () => void;
  onDeleteCategoryClick: () => void;
  onCreateProductClick: () => void;
}

export function CategoryCard(props: IProps) {
  return (
    <Stack
      orientation='vertical'
      className='category-selected-ljnav'
      gap={10}
      contentFill={[0, 1]}
    >
      <Stack
        orientation='horizontal'
        gap={20}
      >
        {props.category.imageId !== guidEmpty.empty ? (
          <img
            className='category-img-Jdys'
            src={ApiImageService.getUrl(props.category.imageId)}
            alt={props.category?.title}
          ></img>
        ) : (
          <div className='category-img-Jdys'>
            <i className='bi bi-card-image' />
          </div>
        )}

        <Stack
          orientation='vertical'
          gap={10}
        >
          <Header>{props.category?.title}</Header>
          <Stack
            orientation='vertical'
            gap={5}
          >
            <button
              className='button-aadj'
              onClick={props.onCreateProductClick}
            >
              Добавить товар
            </button>
            <button
              className='button-aadj'
              onClick={props.onUpdateCategoryClick}
            >
              Изменить категорию
            </button>
            <button
              className='button-aadj'
              onClick={props.onDeleteCategoryClick}
            >
              Удалить категорию
            </button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
