import { IImportFile, IReview } from 'common/models';
import { Stack, Scroll } from 'components';
import './style.css';
import { ImportFileItem } from '..';
import { useRef } from 'react';

interface IProps {
  importFiles: IImportFile[];
  onDeleteClick: (importFile: IImportFile) => void;
  onCreateClick: () => void;
}

export function ImportFilesList(props: IProps) {
  const reviews = props.importFiles;

  const listContent =
    props.importFiles.length === 0 ? (
      <div className='no-import-files-lskaf'>
        <i>Нет файлов...</i>
      </div>
    ) : (
      <Scroll>
        <Stack
          orientation='vertical'
          gap={20}
        >
          {reviews.map((x, i) => (
            <ImportFileItem
              key={i}
              importFile={x}
              onDownloadClick={() => {}}
              onDeleteclick={() => props.onDeleteClick(x)}
            />
          ))}
        </Stack>
      </Scroll>
    );

  const handleDownloadImportFile = async () => {
    props.onCreateClick();
  };

  return (
    <Stack
      orientation='vertical'
      className='import-files-list-slkj'
      contentFill={[0, 1]}
      fill={true}
      gap={20}
    >
      <Stack
        orientation='horizontal'
        className='title-ksajjdv'
        gap={10}
      >
        Импорты
        <div
          className='button-add-import-file-smiaokn'
          onClick={handleDownloadImportFile}
        >
          +
        </div>
      </Stack>
      {listContent}
    </Stack>
  );
}
