import { IImportFile, IReview } from 'common/models';
import { ApiClient } from 'common/api';
import { telperUrls } from 'common/consts';

enum ImportFilesNodes {
  Imports = '/imports',
}

export class ApiImportFileService {
  private static instance: ApiImportFileService | null = null;

  private apiService: ApiClient;

  private constructor() {
    this.apiService = ApiClient.getInstance();
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiImportFileService();
    }

    return this.instance;
  }

  async getAll(): Promise<IImportFile[]> {
    const response = await this.apiService.sendRequest<IImportFile[]>('get', ImportFilesNodes.Imports, {}, true);
    return response;
  }

  static getUrl(id: string): string {
    return telperUrls.api + ImportFilesNodes.Imports + '/' + id;
  }

  async create(file?: File): Promise<IImportFile> {
    const result = await this.apiService.sendRequest<IImportFile>(
      'post',
      ImportFilesNodes.Imports,
      {
        file: file,
      },
      true,
      true
    );
    return result;
  }

  async delete(importFileId: string) {
    const address = `${ImportFilesNodes.Imports}`;
    await this.apiService.sendRequest<IReview[]>('delete', address, { id: importFileId });
  }
}
