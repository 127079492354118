type HistoryChangingMode = 'replace' | 'push';

export class AddressBarService {
  private static instance: AddressBarService | undefined;

  // Пример: https://cp.telper.su/page1/page2
  private readonly protocol: string; // https
  private readonly domain: string; // cp.telper.su
  private readonly localAddress: string[] = []; // ['page1', 'page2']
  private readonly queryParams: { [key: string]: string } = {};
  private readonly urlRegex = /^(?<protocol>\w+):\/\/(?<domain>([\w.:-])+)\/?(?<local>[^?]*)?(\?(?<query>.*))?$/gm;

  private constructor() {
    const url = window.location.href;
    const match = this.urlRegex.exec(url);

    if (match == null || match.groups == null) {
      throw new Error('Invalid URL Regex passed!');
    }

    this.protocol = match.groups.protocol;
    this.domain = match.groups.domain;
    const localAddress = match.groups.local;
    const queryString = match.groups.query;

    if (this.protocol == null || this.domain == null) {
      throw new Error('Invalid URL Regex passed!');
    }

    if (localAddress != null) {
      this.localAddress = localAddress.split('/');
    }

    if (queryString != null) {
      const queryParts = match.groups.query.split('&');

      for (const keyValue of queryParts) {
        const [queryKey, queryValue] = keyValue.split('=');

        if (queryValue == null) {
          continue;
        }

        this.queryParams[queryKey] = queryValue;
      }
    }
  }

  static getInstance(): AddressBarService {
    if (this.instance == null) {
      this.instance = new AddressBarService();
    }

    return this.instance;
  }

  /**
   * Обновить адрес страницы в адресной строке без переадресации на неё.
   * @param page Новый сегмент адреса.
   * @param level Уровень сегмента: -1 заменит последний сегмент.
   * @param history Добавлять ли установленную страницу в историю.
   */
  setPageAddress(page: string, level: number, history: HistoryChangingMode = 'replace') {
    if (this.localAddress.length === 0 && (level === 0 || level === -1)) {
      this.localAddress.push(page);
    } else if (level < 0) {
      this.localAddress[this.localAddress.length - level * -1] = page;
    } else {
      this.localAddress[level] = page;
    }
    this.updateAddressBarQueryParams(history);
  }


  /**
   * Получить query-параметр из адресной строки.
   * @param key Ключ query-параметра.
   * @param remove Удалить ли параметр из адресной строки после получения.
   * @param history Добавить ли новое состояние адресной строки в историю поиска браузера.
   * @returns Строкове значение параметра, если он найден, либо null - если не найден.
   */
  getQueryParam(
    key: string,
    remove: boolean = false,
    history: HistoryChangingMode = 'replace'
  ): string | null {
    const result = this.queryParams[key];

    if (remove && result != null) {
      delete this.queryParams[key];
      this.updateAddressBarQueryParams(history);
    }

    return result == null ? null : result;
  }

  /**
   * Обновить отображаемые в адресной строке Query-параметры.
   * @param history Добавить ли новое состояние адресной строки в историю поиска браузера.
   */
  private updateAddressBarQueryParams(history: HistoryChangingMode) {
    let address = this.localAddress.join('/');

    const queryKeys = Object.keys(this.queryParams);

    if (queryKeys.length > 0) {
      address += '?';
    }

    for (let i = 0; i < queryKeys.length; i++) {
      if (i !== 0) {
        address += '&';
      }

      address += `${queryKeys[i]}=${this.queryParams[queryKeys[i]]}`;
    }

    if (history === 'replace') {
      window.history.replaceState({}, '', address);
    } else if (history === 'push') {
      window.history.pushState({}, '', address);
    } else {
      throw new Error('Unexpected history updated method');
    }
  }
}

