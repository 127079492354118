import { IAccount } from 'common/models';
import { Stack, Scroll } from 'components';
import './style.css';
import { AccountItem } from '../account-item/AccountItem';

interface IProps {
  accounts: IAccount[];
  onCreateClick: () => void;
  onEditClick: (account: IAccount) => void;
  onDeleteClick: (account: IAccount) => void;
}

export function AccountsList(props: IProps) {
  const accounts = props.accounts;

  const listContent =
    props.accounts.length === 0 ? (
      <div className='no-accounts-lskaf'>
        <i>Нет аккаунтов...</i>
      </div>
    ) : (
      <Scroll>
        <Stack
          orientation='vertical'
          gap={20}
        >
          {accounts.map((x, i) =>
            i !== 0 ? (
              <AccountItem
                key={i}
                account={x}
                onUpdateClick={() => {
                  props.onEditClick(x);
                }}
                onDeleteclick={() => {
                  props.onDeleteClick(x);
                }}
              />
            ) : (
              <AccountItem
                key={i}
                account={x}
                onUpdateClick={() => {
                  props.onEditClick(x);
                }}
                onDeleteclick={() => {
                  props.onDeleteClick(x);
                }}
                disableDelete={true}
              />
            )
          )}
        </Stack>
      </Scroll>
    );

  return (
    <Stack
      orientation='vertical'
      className='accounts-list-slkj'
      contentFill={[0, 1]}
      fill={true}
      gap={20}
    >
      <Stack
        orientation='horizontal'
        className='title-acllk'
        gap={10}
      >
        Аккаунты
        <div
          className='button-add-account-smiaokn'
          onClick={props.onCreateClick}
        >
          +
        </div>
      </Stack>
      {listContent}
    </Stack>
  );
}
