import { Modal, Stack } from 'components';
import './style.css';

export function ModalLoader() {
  return (
    <Modal size='fullscreen'>
      <Stack
        orientation='horizontal'
        contentFill={[1, 0, 1]}
      >
        <div />
        <Stack
          orientation='vertical'
          contentFill={['100px', 1]}
        >
          <div></div>
          <div className='loader-NAjsy'></div>
        </Stack>
        <div />
      </Stack>
    </Modal>
  );
}
