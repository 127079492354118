import { ApiError } from 'common/errors';
import { ApiErrorCode } from 'common/enums';
import { NavigatorService } from 'common/services';

export async function handleRequestAuthError<T>(task: () => Promise<T>) {
  try {
    const result = await task();
    return result;
  } catch (e) {
    if (e instanceof ApiError && e.code === ApiErrorCode.Unauthorized) {
      const navigator = NavigatorService.getInstance();
      navigator.toAuthPage();
    }

    throw e;
  }
}
