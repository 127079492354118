import { storageKeys } from 'common/consts';

export class AuthService {
  private static instance: AuthService | null = null;

  private constructor() { }

  public static getInstance(): AuthService {
    if (this.instance == null) {
      this.instance = new AuthService();
    }

    return this.instance;
  }

  setJwtToken(token: string) {
    localStorage.setItem(storageKeys.AUTH_TOKEN, token);
  }

  getJwtToken(): string | null {
    return localStorage.getItem(storageKeys.AUTH_TOKEN);
  }
}
