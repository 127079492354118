import { Modal, Stack } from 'components';
import { IImportFile } from 'common/models';
import { ApiImportFileService } from 'common/api';
import { handleRequestAuthError } from 'common/error-handlers';

interface DateFormatProps {
  dateString: string;
}

const DateFormat: React.FC<DateFormatProps> = ({ dateString }) => {
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const year = String(date.getFullYear()).slice(-2); // Берем последние 2 цифры года
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  return <div>{formatDate(dateString)}</div>;
};

interface IModalProps {
  onClose: () => void;
  importFile: IImportFile;
}

export function DeleteImportFileModal(props: IModalProps) {
  const handleDelete = async () => {
    try {
      const apiService = ApiImportFileService.getInstance();
      await handleRequestAuthError(async () => {
        await apiService.delete(props.importFile.id);
        props.onClose();
      });
    } catch {
      props.onClose();
    }
  };

  return (
    <Modal
      onClose={props.onClose}
      size='large'
    >
      <Stack
        orientation='vertical'
        gap={20}
        fill={true}
        style={{
          textAlign: 'center',
        }}
      >
        <Stack
          orientation='horizontal'
          gap={5}
        >
          Вы действительно хотите удалить файл от{' '}
          <b>
            <DateFormat dateString={props.importFile.importedAt} />
          </b>
          ?
        </Stack>

        <Stack
          orientation='horizontal'
          gap={20}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button onClick={handleDelete}>Да</button>

          <button onClick={props.onClose}>Нет</button>
        </Stack>
      </Stack>
    </Modal>
  );
}
