import { useImmer } from 'use-immer';
import { IAccount } from 'common/models';
import { Button, Checkbox, Loader, Modal, Stack, Textbox } from 'components';
import './style.css';
import { handleRequestAuthError } from 'common/error-handlers';
import { ApiAccountsService } from 'common/api';

type Stage = 'create' | 'update' | 'loading' | 'success' | 'error';

interface IProps {
  account?: IAccount;
  onClose: () => void;
  onAccountsChanged: () => void;
}

interface IState {
  stage: Stage;
  login: string;
  password: string;
  isAdmin: boolean;
  showPassword: boolean;
}

export function AddUpdateAccountModal(props: IProps) {
  const [state, updateState] = useImmer<IState>({
    stage: props.account === undefined ? 'create' : 'update',
    login: props.account?.login ?? '',
    password: '',
    isAdmin: props.account?.isAdmin ?? true,
    showPassword: false,
  });

  const createAccount = async () => {
    updateState(x => {
      x.stage = 'loading';
    });
    const apiService = ApiAccountsService.getInstance();
    try {
      await handleRequestAuthError(async () => {
        await apiService.create(state.login, state.password, state.isAdmin);
      });
      props.onAccountsChanged();
      updateState(x => {
        x.stage = 'success';
      });
    } catch {
      updateState(x => {
        x.stage = 'error';
      });
    }
  };

  const updateAccount = async () => {
    updateState(x => {
      x.stage = 'loading';
    });
    const apiService = ApiAccountsService.getInstance();
    try {
      await handleRequestAuthError(async () => {
        await apiService.update(props.account?.id ?? '', state.login, state.password, state.isAdmin);
      });
      props.onAccountsChanged();
      updateState(x => {
        x.stage = 'success';
      });
    } catch {
      updateState(x => {
        x.stage = 'error';
      });
    }
  };

  const handleAcceptClick = () => {
    if (props.account != null) {
      updateAccount();
    } else {
      createAccount();
    }
  };

  if (state.stage === 'loading') {
    return (
      <Modal size='fullscreen'>
        <div className='loader-container-OAmoaq'>
          <Loader />
        </div>
      </Modal>
    );
  }

  if (state.stage === 'success' || state.stage === 'error') {
    return (
      <Modal size='fullscreen'>
        <Stack
          orientation='horizontal'
          fill
          contentFill={[1, 0, 1]}
        >
          <div />
          <Stack
            orientation='vertical'
            gap={20}
          >
            <div />
            <div />
            <div className='modal-title-aondaeq'>{state.stage === 'success' ? 'Успех' : 'Ошибка'}</div>
            <div>{state.stage === 'success' ? 'Аккаунт был успешно сохранён' : 'Ошибка сохранения аккаунта'}</div>
            <Button onClick={props.onClose}>Закрыть</Button>
          </Stack>
          <div />
        </Stack>
      </Modal>
    );
  }

  const header = (
    <Stack
      orientation='horizontal'
      className='header-ajcakoaln'
      contentFill={[1, 0]}
    >
      {props.account == null ? 'Добавление аккаунта' : 'Изменение аккаунта'}
      <div
        className='close-button-LIanlaq'
        onClick={props.onClose}
      >
        <i className='bi bi-x-lg' />
      </div>
    </Stack>
  );

  const footer = (
    <Stack
      orientation='horizontal'
      contentFill={[1, '130px']}
      className='footer-paonovks'
    >
      <div />
      <Button onClick={handleAcceptClick}>Сохранить</Button>
    </Stack>
  );

  const content = (
    <Stack
      orientation='horizontal'
      gap={10}
      contentFill={[1]}
      fill
    >
      <Stack
        orientation='vertical'
        gap={10}
      >
        <Textbox
          placeholder='Логин'
          value={state.login}
          onChange={v =>
            updateState(x => {
              x.login = v;
            })
          }
        />
        <Stack
          orientation='horizontal'
          gap={10}
          contentFill={[1, 0]}
        >
          <Textbox
            placeholder={props.account === undefined ? 'Пароль' : 'Новый пароль'}
            type={state.showPassword ? 'text' : 'password'}
            value={state.password}
            onChange={v =>
              updateState(x => {
                x.password = v;
              })
            }
          />
          <Button
            onClick={() =>
              updateState(x => {
                x.showPassword = !x.showPassword;
              })
            }
          >
            {state.showPassword ? <i className='bi bi-eye-slash' /> : <i className='bi bi-eye' />}
          </Button>
        </Stack>
        <Stack
          orientation='horizontal'
          gap={10}
          className='account-is-admin-alkjbqa'
        >
          <div>Является админом</div>
          <Checkbox
            value={state.isAdmin}
            onChange={v =>
              updateState(x => {
                x.isAdmin = v;
              })
            }
          ></Checkbox>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <Modal
      size='large'
      className='account-modal-container-aljchq'
    >
      <Stack
        orientation='vertical'
        contentFill={[0, 1, 0]}
        gap={10}
        fill
      >
        {header}
        {content}
        {footer}
      </Stack>
    </Modal>
  );
}
