import { IReview } from 'common/models';
import { Stack } from 'components';
import './style.css';

interface DateFormatProps {
  dateString: string;
}

const DateFormat: React.FC<DateFormatProps> = ({ dateString }) => {
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  return <div className='review-date-alksbb'>{formatDate(dateString)}</div>;
};

interface IProps {
  review: IReview;
  onClick: () => void;
  onDeleteclick: () => void;
}

export function ReviewItem(props: IProps) {
  return (
    <Stack
      orientation='horizontal'
      contentFill={[1, '100px', '100px']}
      gap={20}
      className='review-item-lahuae'
    >
      <Stack
        orientation='vertical'
        contentFill={[0, 1]}
        className='review-item-text-caljbh'
        gap={5}
      >
        <div>
          <b>{props.review.nameAuthor}</b>
        </div>
        <div>"{props.review.text}"</div>
        <DateFormat dateString={props.review.date} />
      </Stack>
      <Stack
        orientation='vertical'
        className='review-item-rating-caljbh'
        contentFill={[1, 1]}
        gap={10}
      >
        <div>
          <b>Рейтинг</b>
        </div>
        <div>{props.review.realRating}</div>
      </Stack>
      <Stack
        orientation='vertical'
        contentFill={[1, 1]}
        gap={10}
        className='review-item-buttons-caljbh'
      >
        <button onClick={props.onClick}>Изменить</button>
        <button onClick={props.onDeleteclick}>Удалить</button>
      </Stack>
    </Stack>
  );
}
