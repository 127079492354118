import { Modal, Stack } from 'components';
import { guidEmpty } from 'common/consts';
import { useImmer } from 'use-immer';
import { IOrderBrief } from 'common/models';
import { useEffect } from 'react';
import { ApiOrdersService } from 'common/api';
import { handleRequestAuthError } from 'common/error-handlers';

interface IModalProps {
  onClose: () => void;
  order: IOrderBrief;
}

interface IState {
  id: string;
  name: string;
}

const initialState: IState = {
  id: guidEmpty.empty,
  name: '',
};

export function DeleteOrderModal(props: IModalProps) {
  const [state, updateState] = useImmer<IState>(initialState);
  useEffect(() => {
    updateState(x => {
      x.id = props.order.id;
      x.name = props.order.name;
    });
  }, []); // eslint-disable-line

  const handleDelete = async () => {
    try {
      const apiService = ApiOrdersService.getInstance();
      await handleRequestAuthError(async () => {
        await apiService.delete(state.id);
        props.onClose();
      });
    } catch {
      updateState(x => {
        x.id = guidEmpty.empty;
        x.name = '';
      });
    }
  };

  return (
    <Modal
      onClose={props.onClose}
      size='large'
    >
      <Stack
        orientation='vertical'
        gap={20}
        fill={true}
        style={{
          textAlign: 'center',
        }}
      >
        <p>
          Вы действительно хотите завершить заказ <b>{state.name}</b>?
        </p>
        <Stack
          orientation='horizontal'
          gap={20}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button onClick={handleDelete}>Да</button>

          <button onClick={props.onClose}>Нет</button>
        </Stack>
      </Stack>
    </Modal>
  );
}
