import { IPropsContainer } from 'common/types';
import { Box, Stack } from 'components';
import './modal.css';
import { useEffect, useRef } from 'react';

type ModalSize = 'tiny' | 'small' | 'medium' | 'large' | 'fullscreen';

interface IModalProps extends IPropsContainer {
  size?: ModalSize;
  onClose?: () => void;
}

export function Modal(props: IModalProps) {
  const { size, className, onClose, style, children } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (props.onClose != null) {
          props.onClose();
        }
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, [props.onClose]); // eslint-disable-line

  let modalSize: string;

  switch (size) {
    case 'tiny':
      modalSize = 'tiny-LmxnHdy';
      break;
    case 'medium':
      modalSize = 'medium-8gYnG';
      break;

    case 'large':
      modalSize = 'large-1Jfhc';
      break;

    case 'fullscreen':
      modalSize = 'fullscreen-Ksk43';
      break;

    case 'small':
    default:
      modalSize = 'small-ldnF5h';
      break;
  }

  const modalClassName = 'modal-kNsldht';
  let contentClassName = `modal-content-kMdht ${modalSize}`;

  if (className != null) {
    contentClassName += ` ${className}`;
  }

  const handleButtonClick = (e: any) => {
    if (e.key === 'Escape' && onClose != null) {
      onClose();
    }
  };

  const getContent = () => {
    if (props.size === 'fullscreen') {
      return (
        <Box
          ref={ref}
          className={contentClassName}
        >
          {children}
        </Box>
      );
    }

    return (
      <Stack
        orientation='horizontal'
        contentFill={[1, 0, 1]}
        fill
      >
        <div />
        <div>
          <Box
            ref={ref}
            className={contentClassName}
          >
            {children}
          </Box>
        </div>
        <div />
      </Stack>
    );
  };

  return (
    <div
      tabIndex={0}
      role='button'
      onKeyDown={handleButtonClick}
      className={modalClassName}
      style={style}
    >
      {getContent()}
    </div>
  );
}
