import { IAccount } from 'common/models';
import { ApiClient } from 'common/api';

enum AccountsNodes {
  Accounts = '/accounts',
}

export class ApiAccountsService {
  private static instance: ApiAccountsService | null = null;

  private apiService: ApiClient;

  private constructor() {
    this.apiService = ApiClient.getInstance();
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiAccountsService();
    }

    return this.instance;
  }

  async create(login: string, password: string, isAdmin: boolean): Promise<IAccount[]> {
    await this.apiService.sendRequest(
      'post',
      AccountsNodes.Accounts,
      {
        login: login,
        password: password,
        isAdmin: isAdmin,
      },
      true,
      true
    );
    return [];
  }

  async update(id: string, login: string, password: string, isAdmin: boolean): Promise<IAccount[]> {
    await this.apiService.sendRequest(
      'put',
      AccountsNodes.Accounts,
      {
        id: id,
        login: login,
        password: password,
        isAdmin: isAdmin,
      },
      true,
      true
    );
    return [];
  }

  async getAll(): Promise<IAccount[]> {
    const response = await this.apiService.sendRequest<IAccount[]>('get', AccountsNodes.Accounts, {}, true);
    return response;
  }

  async delete(accountId: string) {
    const address = `${AccountsNodes.Accounts}/${accountId}`;
    await this.apiService.sendRequest<IAccount[]>('delete', address);
  }
}
