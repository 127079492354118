import { ApiErrorCode } from 'common/enums';

export class ApiError extends Error {
  readonly code: ApiErrorCode;

  constructor(code: ApiErrorCode, message?: string) {
    super(message);

    this.code = code;
  }
}
