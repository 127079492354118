import { ModalLoader, PageSidebar, Stack } from 'components';
import { useImmer } from 'use-immer';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { AuthService } from 'common/services';
import { IImportFile } from 'common/models';
import { ApiImportFileService } from 'common/api';
import { ImportFilesList } from './components';
import { AddImportFileModal, DeleteImportFileModal } from './modals';
import { handleRequestAuthError } from 'common/error-handlers';

interface IState {
  importFile?: IImportFile;
  importFiles?: IImportFile[];
  createImportFileModal?: boolean;
  deleteImportFileModal?: boolean;
}

export function ImportsPage() {
  const [state, updateState] = useImmer<IState>({});
  const navigate = useNavigate();

  useEffect(() => {
    const token = AuthService.getInstance().getJwtToken();
    if (token === null) navigate('/auth');
    loadImportFiles();
  }, []); // eslint-disable-line

  const loadImportFiles = async () => {
    updateState(x => {
      x.importFiles = undefined;
    });
    const importFilesService = ApiImportFileService.getInstance();
    const importFiles = await handleRequestAuthError(async () => await importFilesService.getAll());
    updateState(x => {
      x.importFiles = importFiles;
    });
  };

  return (
    <PageSidebar
      title='Импорты'
      tab='imports'
    >
      {state.importFile && state.deleteImportFileModal && (
        <DeleteImportFileModal
          importFile={state.importFile}
          onClose={() => {
            updateState(x => {
              x.deleteImportFileModal = false;
              x.importFile = undefined;
            });
            loadImportFiles();
          }}
        />
      )}

      {state.createImportFileModal && (
        <AddImportFileModal
          onClose={() => {
            updateState(x => {
              x.createImportFileModal = false;
            });
            loadImportFiles();
          }}
        />
      )}

      {state.importFiles === undefined && <ModalLoader />}
      {state.importFiles !== undefined && (
        <Stack
          orientation='vertical'
          fill={true}
          contentFill={[1]}
        >
          <ImportFilesList
            importFiles={state.importFiles}
            onDeleteClick={v =>
              updateState(x => {
                x.importFile = v;
                x.deleteImportFileModal = true;
              })
            }
            onCreateClick={() =>
              updateState(x => {
                x.createImportFileModal = true;
              })
            }
          />
        </Stack>
      )}
    </PageSidebar>
  );
}
