import { useImmer } from 'use-immer';
import { IOrderBrief, IOrderFull } from 'common/models';
import { Button, Loader, Modal, Scroll, Stack } from 'components';
import './style.css';
import { useEffect } from 'react';
import { ApiOrdersService } from 'common/api';
import { ProductCard } from 'pages/orders-page/components';
import { handleRequestAuthError } from 'common/error-handlers';

type Stage = 'view' | 'loading' | 'success' | 'error';

interface IProps {
  order: IOrderBrief;
  onClose: () => void;
  onComplete: (order: IOrderBrief) => void;
}

interface IState {
  stage: Stage;
  order?: IOrderFull;
}

export function OrderFullModal(props: IProps) {
  const [state, updateState] = useImmer<IState>({
    stage: 'view',
  });

  useEffect(() => {
    loadOrder();
    updateState(x => {
      x.stage = 'view';
    });
  }, []); // eslint-disable-line

  const loadOrder = async () => {
    updateState(x => {
      x.order = undefined;
    });

    try {
      const ordersService = ApiOrdersService.getInstance();
      await handleRequestAuthError(async () => {
        const order = await ordersService.get(props.order.id);
        updateState(x => {
          x.order = order;
        });
      });
    } catch {
      updateState(x => {
        x.stage = 'error';
      });
    }
  };

  if (state.stage === 'loading') {
    return (
      <Modal size='fullscreen'>
        <div className='loader-container-alksjc'>
          <Loader />
        </div>
      </Modal>
    );
  }

  if (state.stage === 'error') {
    return (
      <Modal size='fullscreen'>
        <Stack
          orientation='horizontal'
          fill
          contentFill={[1, 0, 1]}
        >
          <div />
          <Stack
            orientation='vertical'
            gap={20}
          >
            <div />
            <div />
            <div className='modal-title-slkdjq'>Ошибка</div>
            <div>Не удалось загрузить информацию о заказе</div>
            <Button onClick={props.onClose}>Закрыть</Button>
          </Stack>
          <div />
        </Stack>
      </Modal>
    );
  }

  const header = (
    <Stack
      orientation='horizontal'
      className='header-busoafs'
      contentFill={[1, 0]}
    >
      Информация o заказе
      <div
        className='close-button-oiabvja'
        onClick={props.onClose}
      >
        <i className='bi bi-x-lg' />
      </div>
    </Stack>
  );

  const footer = (
    <Stack
      orientation='horizontal'
      contentFill={[1, '130px']}
      className='footer-qlaqblq'
    >
      {/* <Button onClick={() => props.onComplete(props.order)}>Завершить</Button> */}
      <div />
      <Button onClick={props.onClose}>Закрыть окно</Button>
    </Stack>
  );

  const content = state.order && (
    <Stack
      orientation='vertical'
      gap={10}
      contentFill={[0, 1]}
      fill
      className='order-content-alhq'
    >
      <Stack
        orientation='horizontal'
        gap={10}
        contentFill={['150px', '300px', 1]}
      >
        <Stack
          orientation='vertical'
          gap={10}
        >
          <div>Заказчик:</div>
          <div>Номер телефона:</div>
          <div>Сумма заказа:</div>
        </Stack>
        <Stack
          orientation='vertical'
          gap={10}
        >
          <div>
            <b>{state.order.name}</b>
          </div>
          <div>
            <b>
              <a href={'tel:' + props.order.telephone}>{props.order.telephone}</a>
            </b>
          </div>
          <div>
            <b>{state.order.amount} ₽</b>
          </div>
        </Stack>
        <Stack
          orientation='vertical'
          gap={10}
        >
          <div>
            Город: <b>{props.order.city}</b>
          </div>
          <div>Комментарий к заказу</div>
          <div>"{state.order.comment}"</div>
        </Stack>
      </Stack>

      <Scroll>
        <Stack
          orientation='vertical'
          gap={10}
        >
          {state.order.products.map((x, key) => (
            <ProductCard
              key={key}
              product={x}
              onDeleteClick={() => {}}
              onEditClick={() => {}}
            />
          ))}
        </Stack>
      </Scroll>
    </Stack>
  );

  return (
    <Modal size='fullscreen'>
      <Stack
        orientation='vertical'
        contentFill={[0, 1, 0]}
        gap={10}
        fill
      >
        {header}
        {content}
        {footer}
      </Stack>
    </Modal>
  );
}
