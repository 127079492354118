import { IPropsContainer } from 'common/types';
import { useEffect } from 'react';
import './style.css';

interface IPageProps extends IPropsContainer {
  title: string;
}

function getPageTitle(input: string): string {
  return `${input} — Панель управления`;
}

export function Page(props: IPageProps) {
  useEffect(() => {
    document.title = getPageTitle(props.title);
  }, [props.title]);

  let className = 'page-Uej2';

  if (props.className != null) {
    className = `${className} ${props.className}`;
  }

  return (
    <div
      className={className}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
