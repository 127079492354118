import { Stack } from 'components';
import { IAccount } from 'common/models';
import './style.css';

interface IProps {
  account: IAccount;
  onUpdateClick: () => void;
  onDeleteclick: () => void;
  disableDelete?: boolean;
}

export function AccountItem(props: IProps) {
  return (
    <Stack
      orientation='horizontal'
      contentFill={[1, '100px', '100px']}
      gap={20}
      className='account-item-lahuae'
    >
      <Stack
        orientation='vertical'
        contentFill={[0, 1]}
        className='account-item-text-caljbh'
        gap={5}
      >
        <div>Логин</div>
        <div>
          <b>{props.account.login}</b>
        </div>
      </Stack>
      <Stack
        orientation='vertical'
        className='account-item-is-admin-caljbh'
        contentFill={[1, 1]}
        gap={10}
      >
        <div>Админ</div>
        <b>{props.account.isAdmin ? <div>Да</div> : <div>Нет</div>}</b>
      </Stack>
      <Stack
        orientation='vertical'
        contentFill={[1, 1]}
        gap={10}
        className='account-item-buttons-caljbh'
      >
        <button onClick={props.onUpdateClick}>Изменить</button>
        {props.disableDelete ? undefined : <button onClick={props.onDeleteclick}>Удалить</button>}
      </Stack>
    </Stack>
  );
}
