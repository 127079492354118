import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AccountsPage, AuthPage, ImportsPage, OrdersPage, ProductsPage, ReviewsPage } from 'pages';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.css';

const router = createBrowserRouter([
  {
    path: '/auth',
    element: <AuthPage />,
  },
  {
    path: '/products',
    element: <ProductsPage />,
  },
  {
    path: '/reviews',
    element: <ReviewsPage />,
  },
  {
    path: '/accounts',
    element: <AccountsPage />,
  },
  {
    path: '/orders',
    element: <OrdersPage />,
  },
  {
    path: '/imports',
    element: <ImportsPage />,
  },
  {
    path: '/',
    element: <ProductsPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<RouterProvider router={router} />);
