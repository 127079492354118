import { ForwardedRef, forwardRef } from 'react';
import { IPropsContainer } from 'common/types';
import './box.css';

function Box(props: IPropsContainer, ref: ForwardedRef<HTMLDivElement>) {
  const { className, style, children } = props;

  let resultClassName = 'box-QyrKg';

  if (className != null) {
    resultClassName += ` ${className}`;
  }

  return (
    <div
      ref={ref}
      className={resultClassName}
      style={style}
    >
      {children}
    </div>
  );
}

export default forwardRef(Box);
