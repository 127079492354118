import { IReview } from 'common/models';
import { Stack, Scroll } from 'components';
import './style.css';
import { ReviewItem } from '..';

interface IProps {
  reviews: IReview[];
  onCreateClick: () => void;
  onEditClick: (review: IReview) => void;
  onDeleteClick: (review: IReview) => void;
}

export function ReviewsList(props: IProps) {
  const reviews = props.reviews;

  const listContent =
    props.reviews.length === 0 ? (
      <div className='no-reviews-lskaf'>
        <i>Нет отзывов...</i>
      </div>
    ) : (
      <Scroll>
        <Stack
          orientation='vertical'
          gap={20}
        >
          {reviews.map((x, i) => (
            <ReviewItem
              key={i}
              review={x}
              onClick={() => {
                props.onEditClick(x);
              }}
              onDeleteclick={() => {
                props.onDeleteClick(x);
              }}
            />
          ))}
        </Stack>
      </Scroll>
    );

  return (
    <Stack
      orientation='vertical'
      className='reviews-list-slkj'
      contentFill={[0, 1]}
      fill={true}
      gap={20}
    >
      <Stack
        orientation='horizontal'
        className='title-acllk'
        gap={10}
      >
        Отзывы
        <div
          className='button-add-review-smiaokn'
          onClick={props.onCreateClick}
        >
          +
        </div>
      </Stack>
      {listContent}
    </Stack>
  );
}
