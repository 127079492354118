import { Label } from 'components';
import { IPropsContainer } from 'common/types';
import './style.css';

type ButtonTheme = 'default' | 'transparent';

interface IButtonProps extends IPropsContainer {
  type?: ButtonTheme;
  href?: string;
  newTab?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export function Button(props: IButtonProps) {
  let className = 'button-NZhf';

  if (props.type === 'transparent') {
    className += ' trans-Mzn';
  } else {
    className += ' default-Znd';
  }

  if (props.disabled) {
    className += ' disabled-UqH';
  }

  if (props.className != null) {
    className += ` ${props.className}`;
  }

  const handleClick = () => {
    if (!props.disabled && props.onClick != null) {
      props.onClick();
    }
  };

  if (props.href == null) {
    return (
      <div
        role='button'
        tabIndex={0}
        className={className}
        style={props.style}
        onClick={handleClick}
      >
        <Label>{props.children}</Label>
      </div>
    );
  }

  return (
    <a
      className={className}
      style={props.style}
      href={props.href}
      target={props.newTab ? '_blank' : undefined}
      rel={props.newTab ? 'noopener noreferrer' : undefined}
    >
      <Label>{props.children}</Label>
    </a>
  );
}
