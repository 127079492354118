import { IProduct } from 'common/models';
import { Loader, Scroll, Stack } from 'components';
import './style.css';
import { ProductCard } from '../product-card/ProductCard';

interface IProps {
  products?: IProduct[];
  onDeleteClick: (product: IProduct) => void;
  onEditClick: (product: IProduct) => void;
}

export function ProductList(props: IProps) {
  let content: React.ReactNode | null = null;

  if (props.products == null) {
    content = (
      <div className='loader-box-47Hsy'>
        <Loader />
      </div>
    );
  } else if (props.products.length === 0) {
    content = <div className='no-products-J74h'>Нет товаров...</div>;
  } else {
    content = (
      <Scroll>
        <Stack
          orientation='vertical'
          gap={5}
        >
          {props.products.map(x => (
            <ProductCard
              onDeleteClick={() => props.onDeleteClick(x)}
              onEditClick={() => props.onEditClick(x)}
              key={x.id}
              product={x}
            />
          ))}
        </Stack>
      </Scroll>
    );
  }

  return content;
}
