import { IPropsContainer } from 'common/types';
import './scroll.css';

export function Scroll(props: IPropsContainer) {
  let className = 'scroll-Nxbg1';

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div
      className={className}
      style={props.style}
    >
      <div className='content-4Hdba'>{props.children}</div>
    </div>
  );
}
