import { useImmer } from 'use-immer';
import { Button, Page, Stack, Textbox } from 'components';
import { AuthService } from 'common/services';
import { ApiAuthService } from 'common/api';
import { Logo } from './components';
import { FailureModal, LoadingModal, SuccessModal } from './modals';
import { Link } from 'react-router-dom';
import { useRef } from 'react';

interface IState {
  login: string;
  password: string;
  isLoading?: boolean;
  isSuccessModal?: boolean;
  isFailedModal?: boolean;
}

export function AuthPage() {
  const [state, updateState] = useImmer<IState>({
    login: '',
    password: '',
  });
  const refLink = useRef<HTMLAnchorElement>(null);

  const handleAuthClick = async () => {
    updateState(x => {
      x.isLoading = true;
    });

    try {
      const authApiService = ApiAuthService.getInstance();
      const response = await authApiService.auth(state.login, state.password);
      AuthService.getInstance().setJwtToken(response.token);

      updateState(x => {
        x.isLoading = false;
        x.isSuccessModal = true;
      });
      refLink.current?.click();
    } catch {
      updateState(x => {
        x.isLoading = false;
        x.isFailedModal = true;
      });
    }
  };

  return (
    <Page title='Авторизация'>
      <Link to="/" ref={refLink} style={{ display: "none" }}></Link>
      {state.isLoading && <LoadingModal />}

      {state.isFailedModal && (
        <FailureModal
          onClose={() =>
            updateState(x => {
              x.isFailedModal = false;
            })
          }
        />
      )}

      {state.isSuccessModal && (
        <SuccessModal
          onClose={() =>
            updateState(x => {
              x.isSuccessModal = false;
            })
          }
        />
      )}

      <Stack
        orientation='horizontal'
        contentFill={[1, 0, 1]}
      >
        <div />
        <Stack
          orientation='vertical'
          gap={40}
        >
          <div />
          <Logo />
          <Stack
            orientation='vertical'
            gap={10}
          >
            <Textbox
              placeholder='Введите логин...'
              value={state.login}
              onChange={v =>
                updateState(x => {
                  x.login = v;
                })
              }
            />
            <Textbox
              type='password'
              placeholder='Введите пароль...'
              value={state.password}
              onChange={v =>
                updateState(x => {
                  x.password = v;
                })
              }
            />
          </Stack>
          <Button onClick={handleAuthClick}>Войти</Button>
        </Stack>
        <div />
      </Stack>
    </Page>
  );
}
