import { IOrderBrief } from 'common/models';
import './style.css';
import { Label, Stack } from 'components';

interface DateFormatProps {
  dateString: string;
}

const DateFormat: React.FC<DateFormatProps> = ({ dateString }) => {
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  return <div className='review-date-alksbb'>{formatDate(dateString)}</div>;
};

interface IProps {
  order: IOrderBrief;
  onClick: () => void;
  onDeleteclick: () => void;
}

export function OrderItem(props: IProps) {
  return (
    <Stack
      orientation='horizontal'
      contentFill={[1, '150px', '200px', '100px']}
      gap={20}
      className='review-item-lahuae'
    >
      <Stack
        orientation='vertical'
        contentFill={[0, 1]}
        gap={10}
      >
        <div>
          <b>
            {props.order.name} <a href={'tel:' + props.order.telephone}>{props.order.telephone}</a>
          </b>
        </div>
        <div>"{props.order.comment}"</div>
        <DateFormat dateString={props.order.date} />
      </Stack>
      <Stack
        orientation='vertical'
        contentFill={[1, 1]}
        gap={10}
        className='order-city-akucvq'
      >
        <div>
          <b>Город</b>
        </div>
        <Label>{props.order.city}</Label>
      </Stack>
      <Stack
        orientation='vertical'
        contentFill={[1, 1]}
        gap={10}
        className='order-count-product-akucvq'
      >
        <div>
          <b>Количество товаров</b>
        </div>
        <div>{props.order.productCount}</div>
      </Stack>
      <Stack
        orientation='vertical'
        contentFill={[1, 1]}
        gap={10}
        className='review-item-buttons-caljbh'
      >
        <button onClick={props.onClick}>Подробнее</button>
        <button onClick={props.onDeleteclick}>Завершить</button>
      </Stack>
    </Stack>
  );
}
