import { useImmer } from 'use-immer';
import { IReview } from 'common/models';
import { Button, Loader, Modal, Stack, Textbox } from 'components';
import './style.css';
import { handleRequestAuthError } from 'common/error-handlers';
import { ApiReviewsService } from 'common/api/api-review-service';

type Stage = 'create' | 'update' | 'loading' | 'success' | 'error';

interface IProps {
  review?: IReview;
  onClose: () => void;
  onReviewsChanged: () => void;
}

interface IState {
  stage: Stage;
  nameAuthor: string;
  text: string;
  maxRating: number;
  realRating: number;
  date: string;
}

export function AddUpdateReviewModal(props: IProps) {
  const today = new Date(Date.now());
  const [state, updateState] = useImmer<IState>({
    stage: props.review === undefined ? 'create' : 'update',
    nameAuthor: props.review?.nameAuthor ?? '',
    text: props.review?.text ?? '',
    maxRating: props.review?.maxRating ?? 10,
    realRating: props.review?.realRating ?? 10,
    date: props.review?.date ?? today.toISOString(),
  });

  const createReview = async () => {
    updateState(x => {
      x.stage = 'loading';
    });
    const apiService = ApiReviewsService.getInstance();
    try {
      await handleRequestAuthError(async () => {
        await apiService.create(state.nameAuthor, state.text, state.realRating, state.date);
      });
      props.onReviewsChanged();
      updateState(x => {
        x.stage = 'success';
      });
    } catch {
      updateState(x => {
        x.stage = 'error';
      });
    }
  };

  const updateReview = async () => {
    updateState(x => {
      x.stage = 'loading';
    });
    const apiService = ApiReviewsService.getInstance();
    try {
      await handleRequestAuthError(async () => {
        await apiService.update(props.review?.id ?? '', state.nameAuthor, state.text, state.realRating, state.date);
      });
      props.onReviewsChanged();
      updateState(x => {
        x.stage = 'success';
      });
    } catch {
      updateState(x => {
        x.stage = 'error';
      });
    }
  };

  const handleAcceptClick = () => {
    if (props.review != null) {
      updateReview();
    } else {
      createReview();
    }
  };

  if (state.stage === 'loading') {
    return (
      <Modal size='fullscreen'>
        <div className='loader-container-LKNcds'>
          <Loader />
        </div>
      </Modal>
    );
  }

  if (state.stage === 'success' || state.stage === 'error') {
    return (
      <Modal size='fullscreen'>
        <Stack
          orientation='horizontal'
          fill
          contentFill={[1, 0, 1]}
        >
          <div />
          <Stack
            orientation='vertical'
            gap={20}
          >
            <div />
            <div />
            <div className='modal-title-laajv'>{state.stage === 'success' ? 'Успех' : 'Ошибка'}</div>
            <div>{state.stage === 'success' ? 'Отзыв был успешно сохранён' : 'Ошибка сохранения отзыва'}</div>
            <Button onClick={props.onClose}>Закрыть</Button>
          </Stack>
          <div />
        </Stack>
      </Modal>
    );
  }

  const header = (
    <Stack
      orientation='horizontal'
      className='header-busoafs'
      contentFill={[1, 0]}
    >
      {props.review == null ? 'Добавление отзыва' : 'Изменение отзыва'}
      <div
        className='close-button-AUyiai'
        onClick={props.onClose}
      >
        <i className='bi bi-x-lg' />
      </div>
    </Stack>
  );

  const footer = (
    <Stack
      orientation='horizontal'
      contentFill={[1, '130px']}
      className='footer-ISsons'
    >
      <div />
      <Button onClick={handleAcceptClick}>Сохранить</Button>
    </Stack>
  );

  const length = state.maxRating;
  const optionsRating = Array.from({ length }, (_, index) => (
    <option
      key={index}
      value={index + 1}
      className='select-option-alkba'
    >
      {index + 1}
    </option>
  ));

  const content = (
    <Stack
      orientation='horizontal'
      gap={10}
      contentFill={[1]}
      fill
    >
      <Stack
        orientation='vertical'
        gap={10}
      >
        <Textbox
          placeholder='Кто оставил, ФИО'
          value={state.nameAuthor}
          onChange={v =>
            updateState(x => {
              x.nameAuthor = v;
            })
          }
        />
        <textarea
          className='description-AOdcoabi'
          placeholder='Текст отзыва...'
          value={state.text}
          onChange={e =>
            updateState(x => {
              x.text = e.target.value;
            })
          }
        />
        <Stack
          className='review-rating-alkcb'
          orientation='horizontal'
          gap={10}
        >
          <div>Рейтинг</div>
          <div className='select-wrapper-alkbj'>
            <select
              className='select-alaokn'
              value={state.realRating}
              onChange={v =>
                updateState(x => {
                  x.realRating = Number(v.target.value);
                })
              }
            >
              {optionsRating}
            </select>
            <span className='select-icon-aljcnav'>
              <i className='bi bi-arrow-down'></i>
            </span>
          </div>
          <div></div>
          <div></div>
          <div>Дата создания отзыва</div>
          <Textbox
            className='review-date-aljha'
            type='date'
            value={state.date}
            onChange={v => {
              updateState(x => {
                x.date = v;
              });
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <Modal size='fullscreen'>
      <Stack
        orientation='vertical'
        contentFill={[0, 1, 0]}
        gap={10}
        fill
      >
        {header}
        {content}
        {footer}
      </Stack>
    </Modal>
  );
}
