import { ApiClient } from 'common/api';
import { IProduct } from 'common/models';

enum ProductsNodes {
  Products = '/products',
}

export class ApiProductsService {
  private static instance: ApiProductsService | null = null;

  private apiService: ApiClient;

  private constructor() {
    this.apiService = ApiClient.getInstance();
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiProductsService();
    }

    return this.instance;
  }

  async create(
    categoryId: string,
    title: string,
    description: string,
    price: string,
    count: number,
    article: string,
    images: File[]
  ) {
    await this.apiService.sendRequest(
      'post',
      ProductsNodes.Products,
      {
        categoryId: categoryId,
        images: images,
        title: title,
        description: description,
        price: price,
        quantity: count,
        article: article,
      },
      true,
      true
    );
  }

  async update(
    productId: string,
    title: string,
    description: string,
    price: string,
    count: number,
    article: string,
    measureUnit: string,
    imageIds: string[],
    images: File[]
  ) {
    const address = `${ProductsNodes.Products}/${productId}`;
    await this.apiService.sendRequest(
      'put',
      address,
      {
        imageIds: imageIds,
        images: images,
        title: title,
        description: description,
        price: price,
        quantity: count,
        article: article,
        measureUnit: measureUnit,
      },
      true,
      true
    );
  }

  // async get(categoryId: string): Promise<IProduct[]> {
  //   const address = `${ProductsNodes.Products}/${categoryId}`;
  //   const products = await this.apiService.sendRequest<IProduct[]>('get', address);
  //   return products;
  // }

  async delete(productId: string): Promise<void> {
    const address = `${ProductsNodes.Products}/${productId}`;
    await this.apiService.sendRequest<IProduct[]>('delete', address);
  }
}
