import { useRef } from 'react';
import { useImmer } from 'use-immer';
import { ICategory, IProduct } from 'common/models';
import { Button, Loader, Modal, Scroll, Stack, Textbox } from 'components';
import './style.css';
import { handleRequestAuthError } from 'common/error-handlers';
import { ApiImageService, ApiProductsService } from 'common/api';

type Stage = 'create' | 'update' | 'loading' | 'success' | 'error';

interface IProps {
  category: ICategory;
  product?: IProduct;
  onClose: () => void;
  onProductsChanged: () => void;
}

interface IState {
  stage: Stage;
  title: string;
  description: string;
  article: string;
  imageIds: string[];
  price: string;
  count: string;
  files: File[];
  measureUnit: string;
}

function ImageBlock(props: { imageIds: string[]; onDelete: (id: string) => void }) {
  return (
    <Scroll>
      <Stack
        orientation='vertical'
        className='images-block-64Hs'
      >
        {props.imageIds.map((x, key) => (
          <div
            className='product-image-container-Jdna'
            key={key}
          >
            <Button
              className='delete-image-U3jd'
              type='transparent'
              onClick={() => props.onDelete(x)}
            >
              X
            </Button>
            <img
              key={x}
              className='product-image-Jwuya'
              src={ApiImageService.getUrl(x)}
              alt={x}
            />
          </div>
        ))}
      </Stack>
    </Scroll>
  );
}

export function AddUpdateProductModal(props: IProps) {
  const [state, updateState] = useImmer<IState>({
    stage: props.product == null ? 'create' : 'update',
    title: props.product?.title ?? '',
    description: props.product?.description ?? '',
    article: props.product?.article ?? '',
    imageIds: props.product?.imageIds ?? [],
    price: props.product?.price ?? '0',
    count: props.product?.quantity.toString() ?? '0',
    files: [],
    measureUnit: props.product?.measureUnit ?? '',
  });

  console.log(props.product);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const createProduct = async () => {
    updateState(x => {
      x.stage = 'loading';
    });

    const apiService = ApiProductsService.getInstance();

    try {
      await handleRequestAuthError(async () => {
        await apiService.create(
          props.category.id,
          state.title,
          state.description,
          state.price,
          Number(state.count),
          state.article,
          state.files
        );
      });

      props.onProductsChanged();
      updateState(x => {
        x.stage = 'success';
      });
    } catch {
      updateState(x => {
        x.stage = 'error';
      });
    }
  };

  const updateProduct = async () => {
    updateState(x => {
      x.stage = 'loading';
    });

    const apiService = ApiProductsService.getInstance();

    try {
      await handleRequestAuthError(async () => {
        await apiService.update(
          props.product?.id ?? '',
          state.title,
          state.description,
          state.price,
          Number(state.count),
          state.article,
          state.measureUnit,
          state.imageIds,
          state.files
        );
      });

      props.onProductsChanged();
      updateState(x => {
        x.stage = 'success';
      });
    } catch {
      updateState(x => {
        x.stage = 'error';
      });
    }
  };

  const handleAcceptClick = () => {
    if (props.product != null) {
      updateProduct();
    } else {
      createProduct();
    }
  };

  if (state.stage === 'loading') {
    return (
      <Modal size='fullscreen'>
        <div className='loader-container-Jdys'>
          <Loader />
        </div>
      </Modal>
    );
  }

  if (state.stage === 'success' || state.stage === 'error') {
    return (
      <Modal size='fullscreen'>
        <Stack
          orientation='horizontal'
          fill
          contentFill={[1, 0, 1]}
        >
          <div />
          <Stack
            orientation='vertical'
            gap={20}
          >
            <div />
            <div />
            <div className='modal-title-sjdy'>{state.stage === 'success' ? 'Успех' : 'Ошибка'}</div>
            <div>{state.stage === 'success' ? 'Товар был успешно сохранён' : 'Ошибка сохранения товара'}</div>
            <Button onClick={props.onClose}>Закрыть</Button>
          </Stack>
          <div />
        </Stack>
      </Modal>
    );
  }

  const header = (
    <Stack
      orientation='horizontal'
      className='header-Hdydh'
      contentFill={[1, 0]}
    >
      {props.product == null ? 'Добавление товара' : 'Изменение товара'}
      <div
        className='close-button-Hfy'
        onClick={props.onClose}
      >
        <i className='bi bi-x-lg' />
      </div>
    </Stack>
  );

  const footer = (
    <Stack
      orientation='horizontal'
      contentFill={[1, '130px']}
      className='footer-Jdhsy'
    >
      <div />
      <Button onClick={handleAcceptClick}>Сохранить</Button>
    </Stack>
  );

  const content = (
    <Stack
      orientation='horizontal'
      gap={10}
      contentFill={['250px', 1]}
      fill
    >
      <Stack
        orientation='vertical'
        gap={5}
        fill
        contentFill={[0, 1]}
      >
        <Button
          className='load-images-button-U3gah'
          type='transparent'
          onClick={() => fileInputRef.current?.click()}
        >
          {state.files.length === 0 ? 'Загрузить изображения' : `Выбрано ${state.files.length} изображений`}
        </Button>
        <ImageBlock
          imageIds={state.imageIds}
          onDelete={imageId =>
            updateState(x => {
              x.imageIds = x.imageIds.filter(y => y !== imageId);
            })
          }
        />
        <input
          ref={fileInputRef}
          style={{ display: 'none' }}
          type='file'
          multiple
          accept='image/png, image/jpeg'
          onChange={e =>
            updateState(x => {
              x.files = Array.from(e.target.files ?? []);
            })
          }
        />
      </Stack>
      <Stack
        orientation='vertical'
        gap={10}
      >
        <Textbox
          placeholder='Название товара'
          value={state.title}
          onChange={v =>
            updateState(x => {
              x.title = v;
            })
          }
        />
        <textarea
          className='description-JSu2'
          placeholder='Описание товара...'
          value={state.description}
          onChange={e =>
            updateState(x => {
              x.description = e.target.value;
            })
          }
        />
        <Stack
          style={{ alignItems: 'center' }}
          orientation='horizontal'
          contentFill={[0, 1]}
          gap={5}
        >
          <div>Артикул:</div>
          <Textbox
            placeholder='Артикул...'
            value={state.article}
            onChange={v =>
              updateState(x => {
                x.article = v;
              })
            }
          />
        </Stack>

        <Stack
          orientation='horizontal'
          gap={40}
        >
          <Stack
            style={{ alignItems: 'center' }}
            orientation='horizontal'
            contentFill={[0, '150px', 0]}
            gap={5}
          >
            <div>Цена:</div>
            <Textbox
              value={state.price}
              onChange={v =>
                updateState(x => {
                  x.price = v;
                })
              }
            />
            <div>₽</div>
          </Stack>
          <Stack
            style={{ alignItems: 'center' }}
            orientation='horizontal'
            contentFill={[0, '150px', 0]}
            gap={5}
          >
            <div>Количество:</div>
            <Textbox
              type='number'
              value={state.count}
              onChange={v =>
                updateState(x => {
                  x.count = v;
                })
              }
            />
            <div>Единица измерения:</div>
            <Textbox
              type='text'
              value={state.measureUnit}
              onChange={v =>
                updateState(x => {
                  x.measureUnit = v;
                })
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <Modal size='fullscreen'>
      <Stack
        orientation='vertical'
        contentFill={[0, 1, 0]}
        gap={10}
        fill
      >
        {header}
        {content}
        {footer}
      </Stack>
    </Modal>
  );
}
