import { IProduct } from 'common/models';
import { Button, Label, Stack } from 'components';
import { ApiImageService } from 'common/api';
import './style.css';
import { formatPrice } from 'common/helpers';

interface IProps {
  product: IProduct;
  onDeleteClick: () => void;
  onEditClick: () => void;
}

export function ProductCard(props: IProps) {
  const totalPriceString = Number.isNaN(props.product.price) ? '-' : formatPrice(props.product.price.toString());

  const imageBlock =
    props.product.imageIds.length > 0 ? (
      <img
        className='image-block-Jdy3'
        src={ApiImageService.getUrl(props.product.imageIds[0])}
        alt='product_image'
      ></img>
    ) : (
      <div className='image-block-Jdy3'>
        <i className='bi bi-image'></i>
      </div>
    );

  return (
    <Stack
      className='product-card-74js'
      orientation='horizontal'
      gap={10}
      contentFill={[0, 1, 0]}
    >
      {imageBlock}
      <Stack
        orientation='vertical'
        gap={0}
      >
        <div onClick={props.onEditClick}>
          <Label className='product-title-Jsy1'>{props.product.title}</Label>
        </div>
        <Stack
          className='product-info-U3js'
          orientation='horizontal'
          gap={10}
        >
          <div>Цена: {totalPriceString}</div>
          <div>
            Кол-во: {props.product.quantity} {props.product.measureUnit}
          </div>
        </Stack>
      </Stack>
      <Button
        className='delete-button-Jeys'
        type='transparent'
        onClick={props.onDeleteClick}
      >
        X
      </Button>
    </Stack>
  );
}
