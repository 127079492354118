import { useImmer } from 'use-immer';
import { IImportFile } from 'common/models';
import { Button, Loader, Modal, Stack } from 'components';
import './style.css';
import { handleRequestAuthError } from 'common/error-handlers';
import { ApiImportFileService } from 'common/api';

type Stage = 'create' | 'loading' | 'success' | 'error';

interface IProps {
  importFile?: IImportFile;
  onClose: () => void;
}

interface IState {
  stage: Stage;
  file?: File;
}

export function AddImportFileModal(props: IProps) {
  const [state, updateState] = useImmer<IState>({
    stage: 'create',
  });

  const createImportFile = async () => {
    updateState(x => {
      x.stage = 'loading';
    });
    const apiService = ApiImportFileService.getInstance();
    try {
      await handleRequestAuthError(async () => {
        await apiService.create(state.file);
        updateState(x => {
          x.stage = 'success';
        });
      });
    } catch {
      updateState(x => {
        x.stage = 'error';
      });
    }
  };

  const handleImportFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null && e.target.files.length > 0) {
      const file = e.target.files[0];

      updateState(x => {
        x.file = file;
      });
    }
  };

  const handleAcceptClick = () => {
    createImportFile();
  };

  if (state.stage === 'loading') {
    return (
      <Modal size='large'>
        <Stack
          orientation='vertical'
          gap={10}
        >
          <div className='title-NSAakjscu'>Файл загружается</div>
          Пожалуйста, не закрывайте страницу!
          <div className='loader-container-LKNcds'>
            <Loader />
          </div>
        </Stack>
      </Modal>
    );
  }

  if (state.stage === 'success' || state.stage === 'error') {
    return (
      <Modal size='large'>
        <Stack
          orientation='horizontal'
          fill
          contentFill={[1, 0, 1]}
        >
          <div />
          <Stack
            orientation='vertical'
            gap={20}
          >
            <div className='title-NSAakjscu'>{state.stage === 'success' ? 'Успех' : 'Ошибка'}</div>
            <div>{state.stage === 'success' ? 'Файл был успешно загружен' : 'Ошибка загрузки файла'}</div>
            <Button onClick={props.onClose}>Закрыть</Button>
          </Stack>
          <div />
        </Stack>
      </Modal>
    );
  }

  const header = (
    <Stack
      orientation='horizontal'
      className='header-busoafs'
      contentFill={[1, 0]}
    >
      Загрузка файла импорта
      <div
        className='close-button-auscijlan'
        onClick={props.onClose}
      >
        <i className='bi bi-x-lg' />
      </div>
    </Stack>
  );

  const footer = (
    <Stack
      orientation='horizontal'
      contentFill={[1, '130px']}
      className='footer-ISsons'
    >
      <div />
      <Button onClick={handleAcceptClick}>Загрузить</Button>
    </Stack>
  );

  const content = (
    <Stack
      orientation='horizontal'
      gap={10}
      contentFill={[1]}
      fill
    >
      <Stack
        orientation='vertical'
        gap={10}
      >
        <input
          className='input-file-akjljhajhs'
          type='file'
          name='input-file-Nyssh'
          onChange={handleImportFileSelected}
        />
      </Stack>
    </Stack>
  );

  return (
    <Modal size='large'>
      <Stack
        orientation='vertical'
        contentFill={[0, 1, 0]}
        gap={10}
        fill
      >
        {header}
        {content}
        {footer}
      </Stack>
    </Modal>
  );
}
