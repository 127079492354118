import { IAuthToken } from 'common/models';
import { ApiClient } from 'common/api';

enum AuthNodes {
  Auth = '/auth',
}

export class ApiAuthService {
  private static instance: ApiAuthService | null = null;

  private apiService: ApiClient;

  private constructor() {
    this.apiService = ApiClient.getInstance();
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiAuthService();
    }

    return this.instance;
  }

  async auth(login: string, password: string): Promise<IAuthToken> {
    const result = await this.apiService.sendRequest<IAuthToken>(
      'post',
      AuthNodes.Auth,
      { login: login, password: password },
      false
    );

    return result;
  }
}
