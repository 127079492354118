import { Button, Label, Modal, Stack, Textbox } from 'components';
import { guidEmpty } from 'common/consts';
import { useImmer } from 'use-immer';
import { ApiCategoryService } from 'common/api/api-category-service';
import { ICategory } from 'common/models';
import './style.css';
import { handleRequestAuthError } from 'common/error-handlers';

interface IModalProps {
  onClose: () => void;
  categories: ICategory[];
}

interface IState {
  parentId: string;
  parentTitle: string;
  title: string;
  file?: File;
}

const initialState: IState = {
  title: '',
  parentId: guidEmpty.empty,
  parentTitle: '',
};

export function AddCategoryModal(props: IModalProps) {
  const [state, updateState] = useImmer<IState>(initialState);

  const handleSetParentTitle = async (parentTitle: string) => {
    if (parentTitle === '') {
      updateState(x => {
        x.parentId = guidEmpty.empty;
        x.parentTitle = parentTitle;
      });
    } else {
      updateState(x => {
        x.parentTitle = parentTitle;

        const parentCategory = props.categories.find(x => x.title === parentTitle);

        if (parentCategory !== undefined) {
          x.parentId = parentCategory.id;
        }
      });
    }
  };

  const handleImageSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null && e.target.files.length > 0) {
      const file = e.target.files[0];
      updateState(x => {
        x.file = file;
      });
    }
  };

  const handleAddCategory = async () => {
    try {
      const categoryApiService = ApiCategoryService.getInstance();
      await handleRequestAuthError(async () => await categoryApiService.create(state.title, state.parentId, state.file));
      props.onClose();
    } catch {
      updateState(x => {
        x.parentId = guidEmpty.empty;
        x.parentTitle = '';
        x.title = '';
      });
    }
  };

  return (
    <Modal
      onClose={props.onClose}
      size='large'
    >
      <Stack
        orientation='vertical'
        gap={40}
        fill={true}
      >
        <div className='title-NSAjd'>Добавление категории</div>
        <Stack
          orientation='horizontal'
          gap={20}
        >
          <img
            src={state.file != null ? URL.createObjectURL(state.file) : undefined}
            alt={state.title}
            className='image-JShy'
          />
          <Stack
            orientation='vertical'
            gap={20}
          >
            <Stack orientation='vertical'>
              <div>Название категории</div>
              <Textbox
                type='text'
                placeholder='Введите название...'
                value={state.title}
                onChange={v =>
                  updateState(x => {
                    x.title = v;
                  })
                }
              />
            </Stack>
            <Stack
              orientation='vertical'
              gap={5}
              fill={true}
            >
              <Label>Родительская категория</Label>
              <Textbox
                type='text'
                placeholder='Оставьте пустым или начните вводить название...'
                value={state.parentTitle}
                list='categories-list-nSdy'
                onChange={handleSetParentTitle}
              />
              <datalist id='categories-list-nSdy'>
                {props.categories.map(x => (
                  <option
                    key={x.id}
                    value={x.title}
                  />
                ))}
              </datalist>
            </Stack>
            <input
              className='input-file-Ndhsy'
              type='file'
              name='input-file-Nyssh'
              onChange={handleImageSelected}
            />
          </Stack>
        </Stack>

        <Button
          onClick={handleAddCategory}
          type='transparent'
        >
          Создать категорию
        </Button>
      </Stack>
    </Modal>
  );
}
