import { Modal, Stack } from 'components';
import { IProduct } from 'common/models';
import './style.css';
import { ApiProductsService } from 'common/api';
import { handleRequestAuthError } from 'common/error-handlers';

interface IModalProps {
  onClose: () => void;
  product: IProduct;
}

export function DeleteProductModal(props: IModalProps) {
  const handleDeleteClick = async () => {
    const productsService = ApiProductsService.getInstance();
    await handleRequestAuthError(async () => await productsService.delete(props.product.id));
    props.onClose();
  };

  return (
    <Modal
      onClose={props.onClose}
      size='large'
    >
      <Stack
        orientation='vertical'
        gap={20}
        fill={true}
        style={{
          textAlign: 'center',
        }}
      >
        <p>
          Вы действительно хотите удалить товар <b>{props.product.title}</b>?
        </p>
        <p>
          <b>Все подктаегории и товары также будут удалены!</b>
        </p>
        <Stack
          orientation='horizontal'
          gap={20}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button onClick={handleDeleteClick}>Да</button>

          <button onClick={props.onClose}>Нет</button>
        </Stack>
      </Stack>
    </Modal>
  );
}
