import { IPropsContainer } from 'common/types';
import './style.css';

export function Label(props: IPropsContainer) {
  let className = 'l-container-JZnr';

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div
      style={props.style}
      className={className}
    >
      <div className='l-BZhydn'>{props.children}</div>
    </div>
  );
}
