import { IReview } from 'common/models';
import { ApiClient } from 'common/api';

enum ReviewsNodes {
  Reviews = '/reviews',
}

export class ApiReviewsService {
  private static instance: ApiReviewsService | null = null;

  private apiService: ApiClient;

  private constructor() {
    this.apiService = ApiClient.getInstance();
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiReviewsService();
    }

    return this.instance;
  }

  async getAll(): Promise<IReview[]> {
    const response = await this.apiService.sendRequest<IReview[]>('get', ReviewsNodes.Reviews, {}, true);

    return response;
  }

  async create(nameAuthor: string, text: string, realRating: number, date: string) {
    await this.apiService.sendRequest(
      'post',
      ReviewsNodes.Reviews,
      {
        nameAuthor: nameAuthor,
        text: text,
        realRating: realRating,
        date: date,
      },
      true,
      true
    );
  }

  async update(id: string, nameAuthor: string, text: string, realRating: number, date: string) {
    await this.apiService.sendRequest(
      'put',
      ReviewsNodes.Reviews,
      {
        id: id,
        nameAuthor: nameAuthor,
        text: text,
        realRating: realRating,
        date: date,
      },
      true,
      true
    );
  }

  async delete(reviewId: string) {
    const address = `${ReviewsNodes.Reviews}/${reviewId}`;
    await this.apiService.sendRequest<IReview[]>('delete', address);
  }
}
