import axios, { AxiosError } from 'axios';
import { telperUrls } from 'common/consts';
import { ApiErrorCode } from 'common/enums';
import { ApiError } from 'common/errors';

import type { AxiosRequestConfig } from 'axios';
import { AuthService } from 'common/services';

type RequestMethod = 'post' | 'get' | 'put' | 'delete';

function getAuthHeader(): string {
  const authToken = AuthService.getInstance().getJwtToken();

  if (authToken == null) {
    throw new ApiError(ApiErrorCode.Unauthorized);
  }

  return `Bearer ${authToken}`;
}

function createFormData(input: any): {
  contentType: string;
  formData: FormData;
} {
  let contentType = 'application/x-www-form-urlencoded';
  const formData = new FormData();
  const keys = Object.keys(input);

  for (const key of keys) {
    const obj = input[key];

    if (Array.isArray(obj)) {
      for (const arrObject of obj) {
        if (arrObject instanceof File) {
          contentType = 'multipart/form-data';
        }

        formData.append(key, arrObject);
      }
    } else {
      formData.append(key, obj);
    }
  }

  return {
    contentType: contentType,
    formData: formData,
  };
}

export class ApiClient {
  private static instance: ApiClient | null = null;

  static getInstance(): ApiClient {
    if (this.instance == null) {
      this.instance = new ApiClient();
    }

    return this.instance;
  }

  async sendRequest<TRes>(
    method: RequestMethod,
    endPoint: string,
    body?: any,
    auth: boolean = true,
    form: boolean = false
  ): Promise<TRes> {
    const address = telperUrls.api + endPoint;

    const formSettings = form ? createFormData(body) : null;

    const config: AxiosRequestConfig = {
      method: method,
      url: address,
      data: formSettings?.formData ?? body,
      headers: {
        'Content-Type': formSettings?.contentType ?? 'application/json',
        Authorization: auth ? getAuthHeader() : undefined,
      },
    };

    try {
      const result = await axios<TRes>(config);
      return result.data as TRes;
    } catch (e) {
      if (!(e instanceof AxiosError)) {
        throw e;
      }

      const errorCode =
        e.response?.status == null ? null : Number(e.response.status);

      if (errorCode === ApiErrorCode.BadRequest) {
        throw new ApiError(ApiErrorCode.BadRequest);
      } else if (errorCode === ApiErrorCode.NotFound) {
        throw new ApiError(ApiErrorCode.NotFound);
      } else if (errorCode === ApiErrorCode.Conflict) {
        throw new ApiError(ApiErrorCode.Conflict);
      } else if (errorCode === ApiErrorCode.Unauthorized) {
        throw new ApiError(ApiErrorCode.Unauthorized);
      } else {
        throw new ApiError(ApiErrorCode.Unexpected);
      }
    }
  }
}
