import { Stack } from 'components';
import './style.css';

export function Logo() {
  return (
    <Stack
      className='logo-Ajs'
      orientation='horizontal'
      gap={5}
    >
      <div className='logo-text-AAys'>ГИДРАВЛИКА</div>
      <div className='logo-logo-NZhd'>75</div>
    </Stack>
  );
}
