import { IPropsContainer } from 'common/types';
import './style.css';
import { Stack } from 'components';


interface IHeaderProps extends IPropsContainer {
}

export function Header(props: IHeaderProps) {

    let className = 'header-lrk6';

    if (props.className != null) {
        className = `${className} ${props.className}`;
    }

    return (
        <Stack
            orientation='horizontal'
            className={className}
            style={props.style}
        >
            {props.children}
        </Stack>
    );
}
