import { IPropsContainer } from 'common/types';
import { Page, Sidebar, Stack } from 'components';

type Tab = 'products' | 'reviews' | 'accounts' | 'orders' | 'imports';

interface IProps extends IPropsContainer {
  tab: Tab;
  title: string;
}

export function PageSidebar(props: IProps) {
  return (
    <Page title={props.title}>
      <Stack
        orientation='horizontal'
        contentFill={[0, 1]}
        fill
      >
        <Sidebar tab={props.tab} />
        {props.children}
      </Stack>
    </Page>
  );
}
