import { ICategory } from 'common/models';
import { Stack, Scroll, Button } from 'components';
import './style.css';
import { CategoryItem } from '..';
import { guidEmpty } from 'common/consts';

interface IProps {
  categories: ICategory[];
  onCategorySelect: (category: ICategory) => void;
  onCreateClick: () => void;
}

export function CategoriesList(props: IProps) {
  const coreCategories = props.categories.filter(x => x.parentId === guidEmpty.empty);

  const listContent =
    props.categories.length === 0 ? (
      <div className='no-categories-Hy3'>
        <i>Нет категорий...</i>
      </div>
    ) : (
      <Scroll>
        <Stack orientation='vertical'>
          {coreCategories.map(x => (
            <CategoryItem
              onClick={props.onCategorySelect}
              key={x.id}
              categories={props.categories}
              category={x}
            />
          ))}
        </Stack>
      </Scroll>
    );

  return (
    <Stack
      orientation='vertical'
      className='categories-list-Nz2'
      contentFill={[0, 1, 0]}
      gap={10}
    >
      <div className='title-Hf63'>Категории товаров</div>
      {listContent}
      <Button onClick={props.onCreateClick}>Создать категорию</Button>
    </Stack>
  );
}
