import { ApiAccountsService } from 'common/api';
import { IAccount } from 'common/models';
import { AuthService } from 'common/services';
import { ModalLoader, PageSidebar, Stack } from 'components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { AccountsList } from './components';
import { AddUpdateAccountModal, DeleteAccountModal } from './modals';
import { handleRequestAuthError } from 'common/error-handlers';

interface IState {
  account?: IAccount;
  accounts?: IAccount[];
  createAccountModal?: boolean;
  deleteAccountModal?: boolean;
  updateAccountModal?: boolean;
}

export function AccountsPage() {
  const [state, updateState] = useImmer<IState>({});
  const navigate = useNavigate();

  useEffect(() => {
    const token = AuthService.getInstance().getJwtToken();
    if (token === null) navigate('/auth');
    loadAccounts();
  }, []); // eslint-disable-line

  const loadAccounts = async () => {
    updateState(x => {
      x.accounts = undefined;
    });
    const accountsService = ApiAccountsService.getInstance();
    const accounts = await handleRequestAuthError(async () => await accountsService.getAll());
    updateState(x => {
      x.accounts = accounts;
    });
  };

  return (
    <PageSidebar
      title='Аккаунты'
      tab='accounts'
    >
      {state.createAccountModal && (
        <AddUpdateAccountModal
          onClose={() => {
            updateState(x => {
              x.createAccountModal = false;
            });
          }}
          onAccountsChanged={loadAccounts}
        />
      )}

      {state.deleteAccountModal && state.account && (
        <DeleteAccountModal
          account={state.account}
          onClose={() => {
            updateState(x => {
              x.deleteAccountModal = false;
              x.account = undefined;
            });
            loadAccounts();
          }}
        />
      )}

      {state.updateAccountModal && state.account && (
        <AddUpdateAccountModal
          account={state.account}
          onClose={() => {
            updateState(x => {
              x.account = undefined;
              x.updateAccountModal = false;
            });
          }}
          onAccountsChanged={loadAccounts}
        />
      )}

      {state.accounts == null && <ModalLoader />}
      {state.accounts != null && (
        <Stack
          orientation='vertical'
          fill={true}
          contentFill={[1]}
        >
          <AccountsList
            accounts={state.accounts}
            onCreateClick={() =>
              updateState(x => {
                x.createAccountModal = true;
              })
            }
            onEditClick={account =>
              updateState(x => {
                x.account = account;
                x.updateAccountModal = true;
              })
            }
            onDeleteClick={account =>
              updateState(x => {
                x.account = account;
                x.deleteAccountModal = true;
              })
            }
          />
        </Stack>
      )}
    </PageSidebar>
  );
}
