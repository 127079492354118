import './style.css';
import { Scroll, Stack } from 'components';
import { OrderItem } from '../order-item/OrderItem';
import { IOrderBrief } from 'common/models';

interface IProps {
  orders: IOrderBrief[];
  onCreateClick: () => void;
  onEditClick: (order: IOrderBrief) => void;
  onDeleteClick: (order: IOrderBrief) => void;
}

export function OrdersList(props: IProps) {
  const orders = props.orders;

  const listContent =
    props.orders.length === 0 ? (
      <div className='no-orders-aoivae'>
        <i>Нет заказов...</i>
      </div>
    ) : (
      <Scroll>
        <Stack
          orientation='vertical'
          gap={20}
        >
          {orders.map((x, i) => (
            <OrderItem
              key={i}
              order={x}
              onClick={() => {
                props.onEditClick(x);
              }}
              onDeleteclick={() => {
                props.onDeleteClick(x);
              }}
            />
          ))}
        </Stack>
      </Scroll>
    );

  return (
    <Stack
      orientation='vertical'
      className='orders-list-lakc'
      contentFill={[0, 1]}
      fill={true}
      gap={20}
    >
      <Stack
        orientation='horizontal'
        className='title-pqpkqmq'
        gap={10}
      >
        Заказы
        {/* <div
          className='button-add-order-apjapi'
          onClick={props.onCreateClick}
        >
          +
        </div> */}
      </Stack>
      {listContent}
    </Stack>
  );
}
