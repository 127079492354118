export class NavigatorService {
  private static instance: NavigatorService | null = null;

  private constructor() {}

  static getInstance(): NavigatorService {
    if (this.instance == null) {
      this.instance = new NavigatorService();
    }

    return this.instance;
  }

  toAuthPage() {
    window.location.assign('/auth');
  }

  toErrorPage(code?: number) {
    window.location.assign('/error');
  }

  toHomePage() {
    window.location.assign('/');
  }
}
