import { IProps } from 'common/types';
import './style.css';

interface ICheckboxProps extends IProps {
  value?: boolean;
  onChange: (value: boolean) => void;
}

export function Checkbox(props: ICheckboxProps) {
  let resultClassName = 'checkbox-amsna';

  if (props.className != null) {
    resultClassName += ` ${props.className}`;
  }

  return (
    <input
      type='checkbox'
      checked={props.value}
      className={resultClassName}
      style={props.style}
      onChange={e => props.onChange(e.target.checked)}
    />
  );
}
