import { ICategory } from 'common/models';
import { Label, Stack } from 'components';
import { useState } from 'react';
import './style.css';

interface IProps {
  category: ICategory;
  categories: ICategory[];
  level?: number;
  onClick: (category: ICategory) => void;
}

export function CategoryItem(props: IProps) {
  const [open, setOpen] = useState(false);
  let calssName = 'category-item-lsna';

  const childCategories = props.categories.filter(x => x.parentId === props.category.id);

  const levelPadder = new Array(props.level ?? 0).fill('—').join('');
  const titleText = levelPadder.length > 0 ? `${levelPadder} ${props.category.title}` : props.category.title;

  const categoryHeader = (
    <Stack
      orientation='horizontal'
      contentFill={[1, 0]}
      gap={5}
      className={calssName}
      onClick={() => props.onClick(props.category)}
    >
      <Label className='category-title-sjUY'>{titleText}</Label>

      {childCategories.length > 0 && (
        <div
          className='button-open-5hA'
          onClick={() => setOpen(!open)}
        >
          {open ? '▴' : '▾'}
        </div>
      )}
    </Stack>
  );

  return open ? (
    <Stack
      orientation='horizontal'
      contentFill={[1]}
    >
      <Stack orientation='vertical'>
        {categoryHeader}
        {childCategories.map(x => (
          <CategoryItem
            onClick={props.onClick}
            level={(props.level || 0) + 1}
            key={x.id}
            categories={props.categories}
            category={x}
          />
        ))}
      </Stack>
    </Stack>
  ) : (
    <Stack
      orientation='horizontal'
      contentFill={[1]}
    >
      {categoryHeader}
    </Stack>
  );
}
