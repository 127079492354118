import { IProductCount } from 'common/models';
import { Stack } from 'components';
import { ApiImageService } from 'common/api';
import './style.css';
import { formatPrice } from 'common/helpers';

interface IProps {
  product: IProductCount;
  onDeleteClick: () => void;
  onEditClick: () => void;
}

export function ProductCard(props: IProps) {
  const totalPriceString = Number.isNaN(props.product.product.price) ? '-' : formatPrice(props.product.product.price.toString());

  const imageBlock =
    props.product.product.imageIds.length > 0 ? (
      <img
        className='image-block-aonakcjb'
        src={ApiImageService.getUrl(props.product.product.imageIds[0])}
        alt='product_image'
      ></img>
    ) : (
      <div className='image-block-aonakcjb'>
        <i className='bi bi-image'></i>
      </div>
    );

  return (
    <Stack
      className='product-card-akbjdc'
      orientation='horizontal'
      gap={10}
      contentFill={[0, 1, 1, 1]}
    >
      {imageBlock}
      <Stack
        orientation='vertical'
        gap={0}
      >
        <Stack
          orientation='vertical'
          gap={5}
        >
          {props.product.product.title}
          <div>Цена: {totalPriceString}</div>
        </Stack>
      </Stack>
      <Stack
        orientation='vertical'
        gap={5}
        className='product-count-alkbc'
      >
        <div>Количество</div>
        <div>
          {props.product.count} {props.product.product.measureUnit}
        </div>
      </Stack>
      <Stack
        orientation='vertical'
        gap={5}
      >
        <div>Артикул</div>
        <div>{props.product.product.article}</div>
      </Stack>
    </Stack>
  );
}
