import { ApiClient } from 'common/api';
import { IOrderBrief, IOrderFull } from 'common/models';

enum OrdersNodes {
  Orders = '/orders',
}

export class ApiOrdersService {
  private static instance: ApiOrdersService | null = null;

  private apiService: ApiClient;

  private constructor() {
    this.apiService = ApiClient.getInstance();
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiOrdersService();
    }

    return this.instance;
  }

  async getAll(): Promise<IOrderBrief[]> {
    const response = await this.apiService.sendRequest<IOrderBrief[]>('get', OrdersNodes.Orders, {}, true);
    return response;
  }

  async get(id: string): Promise<IOrderFull> {
    const address = `${OrdersNodes.Orders}/${id}`;
    const response = await this.apiService.sendRequest<IOrderFull>('get', address, {}, true);
    return response;
  }

  async create(name: string, telephone: string, comment: string, products: { [key: string]: number }) {
    await this.apiService.sendRequest(
      'post',
      OrdersNodes.Orders,
      {
        name: name,
        telephone: telephone,
        comment: comment,
        products: products,
      },
      true,
      true
    );
  }

  async update(id: string, nameAuthor: string, text: string, realRating: number, date: string) {
    await this.apiService.sendRequest(
      'put',
      OrdersNodes.Orders,
      {
        id: id,
        nameAuthor: nameAuthor,
        text: text,
        realRating: realRating,
        date: date,
      },
      true,
      true
    );
  }

  async delete(orderId: string) {
    const address = `${OrdersNodes.Orders}/${orderId}`;
    await this.apiService.sendRequest<IOrderBrief[]>('delete', address);
  }
}
