import { ICategory, IProduct } from 'common/models';
import { Stack } from 'components';
import { CategoryCard, ProductList } from '..';
import './style.css';
import { AddUpdateProductModal, DeleteProductModal } from '../../modals';
import { ApiCategoryService } from 'common/api';
import { handleRequestAuthError } from 'common/error-handlers';
import { useImmer } from 'use-immer';
import { useEffect } from 'react';

interface IProps {
  category: ICategory;
  onCategoryUpdateClick: () => void;
  onCategoryDeleteClick: () => void;
}

interface IState {
  products?: IProduct[];
  createProductModal?: boolean;
  updateProductModal?: boolean;
  deleteProductModal?: boolean;
  product?: IProduct;
}

export function CategoryFrame(props: IProps) {
  const [state, updateState] = useImmer<IState>({});
  useEffect(() => {
    loadProducts();
  }, [props.category]); // eslint-disable-line

  const loadProducts = async () => {
    updateState(x => {
      x.products = undefined;
    });

    const categoryService = ApiCategoryService.getInstance();
    const products = await handleRequestAuthError(async () => await categoryService.getProductsByCategoryId(props.category.id));

    updateState(x => {
      x.products = products;
    });
  };

  return (
    <div className='frame-Udhh'>
      {state.updateProductModal && state.product && (
        <AddUpdateProductModal
          category={props.category}
          product={state.product}
          onProductsChanged={loadProducts}
          onClose={() =>
            updateState(x => {
              x.product = undefined;
              x.updateProductModal = false;
            })
          }
        />
      )}

      {state.createProductModal && (
        <AddUpdateProductModal
          category={props.category}
          onProductsChanged={loadProducts}
          onClose={() => {
            updateState(x => {
              x.product = undefined;
              x.createProductModal = false;
            });
          }}
        />
      )}

      {state.deleteProductModal && state.product && (
        <DeleteProductModal
          onClose={() => {
            updateState(x => {
              x.deleteProductModal = false;
              x.product = undefined;
            });
            loadProducts();
          }}
          product={state.product}
        />
      )}

      <Stack
        orientation='vertical'
        contentFill={[0, 1]}
        gap={10}
        fill
      >
        <CategoryCard
          category={props.category}
          onDeleteCategoryClick={props.onCategoryDeleteClick}
          onUpdateCategoryClick={props.onCategoryUpdateClick}
          onCreateProductClick={() =>
            updateState(x => {
              x.createProductModal = true;
            })
          }
        />
        <ProductList
          products={state.products}
          onDeleteClick={product => {
            updateState(x => {
              x.deleteProductModal = true;
              x.product = product;
            });
          }}
          onEditClick={product =>
            updateState(x => {
              x.updateProductModal = true;
              x.product = product;
            })
          }
        />
      </Stack>
    </div>
  );
}
