import { ModalLoader, PageSidebar } from 'components';
import './style.css';
import { OrdersList } from './components';
import { useImmer } from 'use-immer';
import { useEffect } from 'react';
import { AuthService } from 'common/services';
import { useNavigate } from 'react-router-dom';
import { ApiOrdersService } from 'common/api';
import { IOrderBrief } from 'common/models';
import { DeleteOrderModal, OrderFullModal } from './modals';
import { handleRequestAuthError } from 'common/error-handlers';

interface IState {
  order?: IOrderBrief;
  orders?: IOrderBrief[];
  viewFullOrderModal?: boolean;
  deleteOrderModal?: boolean;
  id?: string;
  name?: string;
}

export function OrdersPage() {
  const [state, updateState] = useImmer<IState>({});
  const navigate = useNavigate();

  useEffect(() => {
    const token = AuthService.getInstance().getJwtToken();
    if (token === null) navigate('/auth');
    loadOrders();
  }, []); // eslint-disable-line

  const loadOrders = async () => {
    updateState(x => {
      x.orders = undefined;
    });

    const ordersService = ApiOrdersService.getInstance();

    await handleRequestAuthError(async () => {
      const orders = await ordersService.getAll();

      updateState(x => {
        x.orders = orders;
      });
    });
  };

  const competeOrder = async (order: IOrderBrief) => {
    updateState(x => {
      x.order = order;
      x.deleteOrderModal = true;
    });
  };

  return (
    <PageSidebar
      title='Заказы'
      tab='orders'
    >
      {state.viewFullOrderModal && state.order && (
        <OrderFullModal
          order={state.order}
          onClose={() => {
            updateState(x => {
              x.order = undefined;
              x.viewFullOrderModal = false;
            });
            loadOrders();
          }}
          onComplete={competeOrder}
        />
      )}

      {state.deleteOrderModal && state.order && (
        <DeleteOrderModal
          onClose={() => {
            updateState(x => {
              x.deleteOrderModal = false;
              if (x.viewFullOrderModal) x.viewFullOrderModal = false;
            });
            loadOrders();
          }}
          order={state.order}
        />
      )}

      {state.orders === undefined && <ModalLoader />}
      {state.orders !== undefined && (
        <OrdersList
          orders={state.orders}
          onCreateClick={() => {}}
          onDeleteClick={competeOrder}
          onEditClick={v => {
            updateState(x => {
              x.order = v;
              x.viewFullOrderModal = true;
            });
          }}
        />
      )}
    </PageSidebar>
  );
}
