import { Modal, Stack } from 'components';
import { guidEmpty } from 'common/consts';
import { useImmer } from 'use-immer';
import { IAccount } from 'common/models';
import { useEffect } from 'react';
import { ApiAccountsService } from 'common/api';
import { handleRequestAuthError } from 'common/error-handlers';

interface IModalProps {
  onClose: () => void;
  account: IAccount;
}

interface IState {
  id: string;
  login: string;
}

const initialState: IState = {
  id: guidEmpty.empty,
  login: '',
};

export function DeleteAccountModal(props: IModalProps) {
  const [state, updateState] = useImmer<IState>(initialState);
  useEffect(() => {
    updateState(x => {
      x.id = props.account.id;
      x.login = props.account.login;
    });
  }, []); // eslint-disable-line

  const handleDeleteAccount = async () => {
    const apiService = ApiAccountsService.getInstance();
    try {
      await handleRequestAuthError(async () => {
        await apiService.delete(state.id);
      });
      props.onClose();
    } catch {
      updateState(x => {
        x.id = guidEmpty.empty;
        x.login = '';
      });
    }
  };

  return (
    <Modal
      onClose={props.onClose}
      size='large'
    >
      <Stack
        orientation='vertical'
        gap={20}
        fill={true}
        style={{
          textAlign: 'center',
        }}
      >
        <p>
          Вы действительно хотите удалить аккаунт <b>{state.login}</b>?
        </p>
        <Stack
          orientation='horizontal'
          gap={20}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button onClick={handleDeleteAccount}>Да</button>

          <button onClick={props.onClose}>Нет</button>
        </Stack>
      </Stack>
    </Modal>
  );
}
