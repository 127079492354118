import { IImportFile, IReview } from 'common/models';
import { Stack } from 'components';
import './style.css';
import { ApiImportFileService } from 'common/api';
import { useRef } from 'react';

interface DateFormatProps {
  dateString: string;
}

const DateFormat: React.FC<DateFormatProps> = ({ dateString }) => {
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const year = String(date.getFullYear()).slice(-2); // Берем последние 2 цифры года
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  return <div>{formatDate(dateString)}</div>;
};

interface IProps {
  importFile: IImportFile;
  onDownloadClick: () => void;
  onDeleteclick: () => void;
}

export function ImportFileItem(props: IProps) {
  const ref = useRef<HTMLAnchorElement>(null);

  return (
    <Stack
      orientation='horizontal'
      contentFill={[1, '100px', '100px']}
      gap={20}
      className='import-file-item-askd'
    >
      <Stack
        orientation='horizontal'
        gap={5}
      >
        Файл от <DateFormat dateString={props.importFile.importedAt} />
      </Stack>
      <button
        className='import-file-item-button-askd'
        onClick={() => {
          if (ref.current !== null) ref.current.click();
        }}
      >
        Скачать
        <a
          ref={ref}
          href={ApiImportFileService.getUrl(props.importFile.id)}
          download
          className='import-file-item-a-askd'
        />
      </button>
      <button
        className='import-file-item-button-askd'
        onClick={props.onDeleteclick}
      >
        Удалить
      </button>
    </Stack>
  );
}
