import { Stack } from 'components';
import './style.css';
import { Logo } from './components';
import { useNavigate } from 'react-router-dom';

type Tab = 'products' | 'reviews' | 'accounts' | 'orders' | 'imports';

interface ISidebarTabProps {
  href: string;
  icon: React.ReactNode;
  title: string;
  selected: boolean;
  disabled?: boolean;
}

function SidebarTab(props: ISidebarTabProps) {
  const navigate = useNavigate();

  let className = 'tab-Nsh3';

  if (props.selected) {
    className += ' selected-4ys';
  } else if (props.disabled) {
    className += ' disabled-hf5';
  } else {
    className += ' enabled-4Tad';
  }

  return (
    <Stack
      orientation='horizontal'
      gap={10}
      className={className}
      onClick={!props.disabled && !props.selected ? () => navigate(props.href) : undefined}
    >
      {props.icon}
      {props.title}
    </Stack>
  );
}

interface ISidebarProps {
  tab: Tab;
}

export function Sidebar(props: ISidebarProps) {
  return (
    <Stack
      className='sidebar-JSy3dy'
      orientation='vertical'
      gap={40}
    >
      <Logo />

      <Stack
        orientation='vertical'
        gap={5}
      >
        <SidebarTab
          icon={<i className='bi bi-box-seam' />}
          title='Товары'
          href='/products'
          selected={props.tab === 'products'}
        />
        <SidebarTab
          icon={<i className='bi bi-cart'></i>}
          title='Заказы'
          href='/orders'
          selected={props.tab === 'orders'}
        />
        <SidebarTab
          icon={<i className='bi bi-chat-square-text' />}
          title='Отзывы'
          href='/reviews'
          selected={props.tab === 'reviews'}
        />
        <SidebarTab
          icon={<i className='bi bi-person' />}
          title='Аккаунты'
          href='/accounts'
          selected={props.tab === 'accounts'}
        />
        <SidebarTab
          icon={<i className='bi bi-filetype-xlsx' />}
          title='Импорты'
          href='/imports'
          selected={props.tab === 'imports'}
        />
      </Stack>
    </Stack>
  );
}
