import { Modal, Stack } from 'components';
import { guidEmpty } from 'common/consts';
import { useImmer } from 'use-immer';
import { IReview } from 'common/models';
import { useEffect } from 'react';
import { ApiReviewsService } from 'common/api';
import { handleRequestAuthError } from 'common/error-handlers';

interface IModalProps {
  onClose: () => void;
  review: IReview;
}

interface IState {
  id: string;
  nameAuthor: string;
}

const initialState: IState = {
  id: guidEmpty.empty,
  nameAuthor: '',
};

export function DeleteReviewModal(props: IModalProps) {
  const [state, updateState] = useImmer<IState>(initialState);
  useEffect(() => {
    updateState(x => {
      x.id = props.review.id;
      x.nameAuthor = props.review.nameAuthor;
    });
  }, []); // eslint-disable-line

  const handleDelete = async () => {
    try {
      const apiService = ApiReviewsService.getInstance();
      await handleRequestAuthError(async () => await apiService.delete(state.id));
      props.onClose();
    } catch {
      updateState(x => {
        x.id = guidEmpty.empty;
        x.nameAuthor = '';
      });
    }
  };

  return (
    <Modal
      onClose={props.onClose}
      size='large'
    >
      <Stack
        orientation='vertical'
        gap={20}
        fill={true}
        style={{
          textAlign: 'center',
        }}
      >
        <p>
          Вы действительно хотите удалить отзыв <b>{state.nameAuthor}</b>?
        </p>
        <Stack
          orientation='horizontal'
          gap={20}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button onClick={handleDelete}>Да</button>

          <button onClick={props.onClose}>Нет</button>
        </Stack>
      </Stack>
    </Modal>
  );
}
