import { IReview } from 'common/models';
import { ModalLoader, PageSidebar, Stack } from 'components';
import { useImmer } from 'use-immer';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { AuthService } from 'common/services';
import { ReviewsList } from './components';
import { AddUpdateReviewModal, DeleteReviewModal } from './modals';
import { ApiReviewsService } from 'common/api';
import { handleRequestAuthError } from 'common/error-handlers';

interface IState {
  review?: IReview;
  reviews?: IReview[];
  createReviewModal?: boolean;
  updateReviewModal?: boolean;
  deleteReviewModal?: boolean;
}

export function ReviewsPage() {
  const [state, updateState] = useImmer<IState>({});
  const navigate = useNavigate();

  useEffect(() => {
    const token = AuthService.getInstance().getJwtToken();
    if (token === null) navigate('/auth');
    loadReviews();
  }, []); // eslint-disable-line

  const loadReviews = async () => {
    updateState(x => {
      x.reviews = undefined;
    });
    const reviewsService = ApiReviewsService.getInstance();
    const reviews = await handleRequestAuthError(async () => await reviewsService.getAll());
    updateState(x => {
      x.reviews = reviews;
    });
  };

  return (
    <PageSidebar
      title='Отзывы'
      tab='reviews'
    >
      {state.createReviewModal && (
        <AddUpdateReviewModal
          onClose={() => {
            updateState(x => {
              x.createReviewModal = false;
            });
            loadReviews();
          }}
          onReviewsChanged={loadReviews}
        />
      )}

      {state.updateReviewModal && state.review && (
        <AddUpdateReviewModal
          review={state.review}
          onClose={() => {
            updateState(x => {
              x.review = undefined;
              x.updateReviewModal = false;
            });
            loadReviews();
          }}
          onReviewsChanged={loadReviews}
        />
      )}

      {state.deleteReviewModal && state.review && (
        <DeleteReviewModal
          review={state.review}
          onClose={() => {
            updateState(x => {
              x.review = undefined;
              x.deleteReviewModal = false;
            });
            loadReviews();
          }}
        />
      )}

      {state.reviews === undefined && <ModalLoader />}
      {state.reviews !== undefined && (
        <Stack
          orientation='vertical'
          fill={true}
          contentFill={[1]}
        >
          <ReviewsList
            reviews={state.reviews}
            onCreateClick={() => {
              updateState(x => {
                x.createReviewModal = true;
              });
            }}
            onEditClick={review =>
              updateState(x => {
                x.review = review;
                x.updateReviewModal = true;
              })
            }
            onDeleteClick={review =>
              updateState(x => {
                x.review = review;
                x.deleteReviewModal = true;
              })
            }
          />
        </Stack>
      )}
    </PageSidebar>
  );
}
