import { ApiCategoryService } from 'common/api';
import { ICategory } from 'common/models';
import { ModalLoader, PageSidebar, Stack } from 'components';
import { useEffect } from 'react';
import { CategoriesList, CategoryFrame } from './components';
import { useImmer } from 'use-immer';
import { AddCategoryModal, DeleteCategoryModal, UpdateCategoryModal } from './modals';
import { AuthService } from 'common/services';
import { useNavigate } from 'react-router-dom';
import { handleRequestAuthError } from 'common/error-handlers';

interface IState {
  category?: ICategory;
  categories?: ICategory[];
  createCategoryModal?: boolean;
  updateCategoryModal?: boolean;
  deleteCategoryModal?: boolean;
}

export function ProductsPage() {
  const [state, updateState] = useImmer<IState>({});
  const navigate = useNavigate();

  useEffect(() => {
    const token = AuthService.getInstance().getJwtToken();
    if (token === null) navigate('/auth');
    loadCategories();
  }, []); // eslint-disable-line

  const loadCategories = async () => {
    const categoriesService = ApiCategoryService.getInstance();
    try {
      const categories = await handleRequestAuthError(async () => categoriesService.getAll());
      updateState(x => {
        x.categories = categories;
      });
    } catch {
      updateState(x => {
        x.categories = [];
      });
    }
  };

  useEffect(() => {
    selectCategory(state.category?.id || '');
  }, [state.categories]); // eslint-disable-line

  const selectCategory = async (id: string) => {
    const category = state.categories?.find(x => x.id === id);
    updateState(x => {
      x.category = category;
    });
  };

  return (
    <PageSidebar
      title='Товары'
      tab='products'
    >
      {state.createCategoryModal && (
        <AddCategoryModal
          categories={state.categories || []}
          onClose={() => {
            updateState(x => {
              x.createCategoryModal = false;
            });
            loadCategories();
          }}
        />
      )}

      {state.updateCategoryModal && state.category && (
        <UpdateCategoryModal
          categories={state.categories || []}
          category={state.category}
          onClose={() => {
            updateState(x => {
              x.updateCategoryModal = false;
            });
            loadCategories();
          }}
        />
      )}

      {state.deleteCategoryModal && state.category && (
        <DeleteCategoryModal
          category={state.category}
          onClose={() => {
            updateState(x => {
              x.deleteCategoryModal = false;
            });
            loadCategories();
          }}
        />
      )}

      {state.categories == null && <ModalLoader />}
      {state.categories != null && (
        <Stack
          orientation='horizontal'
          fill
          contentFill={[0, 1]}
        >
          <CategoriesList
            categories={state.categories}
            onCategorySelect={category => selectCategory(category.id)}
            onCreateClick={() =>
              updateState(x => {
                x.createCategoryModal = true;
              })
            }
          />
          {state.category && (
            <CategoryFrame
              category={state.category}
              onCategoryUpdateClick={() =>
                updateState(x => {
                  x.updateCategoryModal = true;
                })
              }
              onCategoryDeleteClick={() =>
                updateState(x => {
                  x.deleteCategoryModal = true;
                })
              }
            />
          )}
        </Stack>
      )}
    </PageSidebar>
  );
}
