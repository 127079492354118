import { Modal, Stack } from 'components';
import { guidEmpty } from 'common/consts';
import { useImmer } from 'use-immer';
import { ApiCategoryService } from 'common/api/api-category-service';
import { ICategory } from 'common/models';
import './style.css';
import { useEffect } from 'react';
import { handleRequestAuthError } from 'common/error-handlers';

interface IModalProps {
  onClose: () => void;
  category: ICategory;
}

interface IState {
  id: string;
  title: string;
  parentId: string;
}

const initialState: IState = {
  id: guidEmpty.empty,
  parentId: guidEmpty.empty,
  title: '',
};

export function DeleteCategoryModal(props: IModalProps) {
  const [state, updateState] = useImmer<IState>(initialState);
  useEffect(() => {
    updateState(x => {
      x.id = props.category.id;
      x.title = props.category.title;
      x.parentId = props.category.parentId;
    });
  }, []); // eslint-disable-line

  const handleDeleteCategory = async () => {
    try {
      const categoryApiService = ApiCategoryService.getInstance();
      await handleRequestAuthError(async () => await categoryApiService.delete(state.id, state.parentId));
      props.onClose();
    } catch {
      updateState(x => {
        x.id = guidEmpty.empty;
        x.parentId = guidEmpty.empty;
        x.title = '';
      });
    }
  };

  return (
    <Modal
      onClose={props.onClose}
      size='large'
    >
      <Stack
        orientation='vertical'
        gap={20}
        fill={true}
        style={{
          textAlign: 'center',
        }}
      >
        <p>
          Вы действительно хотите удалить категорию <b>{state.title}</b>?
        </p>
        <p>
          <b>Все подктаегории и товары также будут удалены!</b>
        </p>
        <Stack
          orientation='horizontal'
          gap={20}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button onClick={handleDeleteCategory}>Да</button>

          <button onClick={props.onClose}>Нет</button>
        </Stack>
      </Stack>
    </Modal>
  );
}
