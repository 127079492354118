import { ForwardedRef, forwardRef } from 'react';
import { IPropsContainer } from 'common/types';
import './stack.css';

type Gap = 0 | 5 | 10 | 20 | 40;
type StackOrientation = 'horizontal' | 'vertical';

interface IStackProps extends IPropsContainer {
  orientation: StackOrientation;
  gap?: Gap;
  fill?: boolean;
  contentFill?: (number | string)[];
  onClick?: () => void;
}

function Stack(props: IStackProps, ref: ForwardedRef<HTMLDivElement>) {
  const {
    orientation,
    gap,
    fill,
    contentFill,
    className,
    style,
    children,
    id,
  } = props;

  let resultClassName = 'stack-jznghy';

  resultClassName +=
    orientation === 'horizontal' ? ' horizontal-64bzg' : ' vertical-64bzg';

  if (fill) {
    resultClassName += ' fill-OytHg';
  }

  if (gap != null) {
    if (gap === 5) {
      resultClassName += ' gap-5-Knzh';
    } else if (gap === 10) {
      resultClassName += ' gap-10-hNz3';
    } else if (gap === 20) {
      resultClassName += ' gap-20-74Hd';
    } else if (gap === 40) {
      resultClassName += ' gap-40-AgTd';
    }
  }

  if (className != null) {
    resultClassName += ` ${className}`;
  }

  const getChildStyle = (index: number): React.CSSProperties | undefined => {
    if (contentFill != null) {
      // Flex-grow
      if (typeof contentFill[index] === 'number') {
        return { flexGrow: contentFill[index] };
      }

      // Fixed horizontal size
      if (orientation === 'horizontal') {
        return {
          width: contentFill[index],
          minWidth: contentFill[index],
          maxWidth: contentFill[index],
        };
      }

      // Fixed vertical size
      return {
        height: contentFill[index],
        minHeight: contentFill[index],
        maxHeight: contentFill[index],
      };
    }

    return undefined;
  };

  let childrenResult: React.ReactNode | undefined;

  if (children != null) {
    childrenResult = Array.isArray(children) ? (
      children.map((child, i) => (
        <div
          key={i}
          style={getChildStyle(i)}
        >
          {child}
        </div>
      ))
    ) : (
      <div style={getChildStyle(0)}>{children}</div>
    );
  }

  return (
    <div
      id={id}
      ref={ref}
      className={resultClassName}
      style={style}
      tabIndex={props.onClick != null ? 0 : undefined}
      role={props.onClick != null ? 'button' : undefined}
      onClick={props.onClick}
    >
      {childrenResult}
    </div>
  );
}

export default forwardRef(Stack);
