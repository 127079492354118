let formatter: Intl.NumberFormat | null = null;

export function formatPrice(input: string | number): string {
  if (formatter == null) {
    formatter = new Intl.NumberFormat('ru-RU', {
      currency: 'RUB',
      style: 'currency',
    });
  }

  const priceNumber = typeof input === 'string' ? Number(input.replace(',', '.')) : input;
  return formatter.format(priceNumber);
}
