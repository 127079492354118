import { IProps } from 'common/types';
import './style.css';

type TextboxType = 'text' | 'number' | 'password' | 'date';

interface ITextBoxProps extends IProps {
  value?: string;
  placeholder?: string;
  list?: string;
  onChange?: (value: string) => void;
  type?: TextboxType;
  error?: boolean;
}

export function Textbox(props: ITextBoxProps) {
  const handleTextBoxChange = (input: string) => {
    if (props.onChange != null) {
      props.onChange(input);
    }
  };

  let resultClassName = 'textbox-ZMjl5';

  if (props.error) {
    resultClassName += ' error-MZdjY';
  }

  if (props.className != null) {
    resultClassName += ` ${props.className}`;
  }

  return (
    <input
      type={props.type || 'text'}
      list={(props.type && props.type === 'text') || props.type === undefined ? props.list : undefined}
      className={resultClassName}
      style={props.style}
      placeholder={props.placeholder}
      value={props.value}
      onChange={e => handleTextBoxChange(e.target.value)}
    />
  );
}
