import { IImage } from 'common/models';
import { ApiClient } from 'common/api';
import { telperUrls } from 'common/consts';

enum ImageNodes {
  Images = '/images',
}

export class ApiImageService {
  private static instance: ApiImageService | null = null;

  private apiService: ApiClient;

  private constructor() {
    this.apiService = ApiClient.getInstance();
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiImageService();
    }

    return this.instance;
  }

  async create(file: File): Promise<IImage> {
    let formData = new FormData();
    formData.append('fileImage', file);
    const result = await this.apiService.sendRequest<IImage>(
      'post',
      ImageNodes.Images,
      formData
    );

    return result;
  }

  static getUrl(id: string): string {
    return telperUrls.api + ImageNodes.Images + '/' + id;
  }
}
